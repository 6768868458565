var er = Object.defineProperty;
var tr = (t, e, n) => e in t ? er(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var Oe = (t, e, n) => (tr(t, typeof e != "symbol" ? e + "" : e, n), n);
var nr = /* @__PURE__ */ ((t) => (t.Error = "error", t.Warning = "warning", t.Info = "info", t.Success = "success", t))(nr || {});
const Jr = (t) => t !== null && typeof t == "object" && "data" in t, Xr = (t) => t !== null && typeof t == "object" && "error" in t;
class Zr extends Error {
  constructor(n) {
    super(n == null ? void 0 : n.message);
    Oe(this, "apiError");
    this.name = "NylasAPIError", this.stack = new Error().stack, this.apiError = n;
  }
}
async function ln(t) {
  const n = new TextEncoder().encode(t), r = await crypto.subtle.digest("SHA-256", n), i = Array.from(new Uint8Array(r)).map((u) => u.toString(16).padStart(2, "0")).join("");
  return btoa(i).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}
const I = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global, B = Object.keys, M = Array.isArray;
function U(t, e) {
  return typeof e != "object" || B(e).forEach(function(n) {
    t[n] = e[n];
  }), t;
}
typeof Promise > "u" || I.Promise || (I.Promise = Promise);
const Fe = Object.getPrototypeOf, rr = {}.hasOwnProperty;
function W(t, e) {
  return rr.call(t, e);
}
function Pe(t, e) {
  typeof e == "function" && (e = e(Fe(t))), (typeof Reflect > "u" ? B : Reflect.ownKeys)(e).forEach((n) => {
    Z(t, n, e[n]);
  });
}
const xn = Object.defineProperty;
function Z(t, e, n, r) {
  xn(t, e, U(n && W(n, "get") && typeof n.get == "function" ? { get: n.get, set: n.set, configurable: !0 } : { value: n, configurable: !0, writable: !0 }, r));
}
function ke(t) {
  return { from: function(e) {
    return t.prototype = Object.create(e.prototype), Z(t.prototype, "constructor", t), { extend: Pe.bind(null, t.prototype) };
  } };
}
const sr = Object.getOwnPropertyDescriptor;
function Jt(t, e) {
  let n;
  return sr(t, e) || (n = Fe(t)) && Jt(n, e);
}
const ir = [].slice;
function ut(t, e, n) {
  return ir.call(t, e, n);
}
function En(t, e) {
  return e(t);
}
function Ie(t) {
  if (!t)
    throw new Error("Assertion Failed");
}
function Pn(t) {
  I.setImmediate ? setImmediate(t) : setTimeout(t, 0);
}
function An(t, e) {
  return t.reduce((n, r, s) => {
    var i = e(r, s);
    return i && (n[i[0]] = i[1]), n;
  }, {});
}
function ee(t, e) {
  if (W(t, e))
    return t[e];
  if (!e)
    return t;
  if (typeof e != "string") {
    for (var n = [], r = 0, s = e.length; r < s; ++r) {
      var i = ee(t, e[r]);
      n.push(i);
    }
    return n;
  }
  var o = e.indexOf(".");
  if (o !== -1) {
    var u = t[e.substr(0, o)];
    return u === void 0 ? void 0 : ee(u, e.substr(o + 1));
  }
}
function z(t, e, n) {
  if (t && e !== void 0 && (!("isFrozen" in Object) || !Object.isFrozen(t)))
    if (typeof e != "string" && "length" in e) {
      Ie(typeof n != "string" && "length" in n);
      for (var r = 0, s = e.length; r < s; ++r)
        z(t, e[r], n[r]);
    } else {
      var i = e.indexOf(".");
      if (i !== -1) {
        var o = e.substr(0, i), u = e.substr(i + 1);
        if (u === "")
          n === void 0 ? M(t) && !isNaN(parseInt(o)) ? t.splice(o, 1) : delete t[o] : t[o] = n;
        else {
          var a = t[o];
          a && W(t, o) || (a = t[o] = {}), z(a, u, n);
        }
      } else
        n === void 0 ? M(t) && !isNaN(parseInt(e)) ? t.splice(e, 1) : delete t[e] : t[e] = n;
    }
}
function Sn(t) {
  var e = {};
  for (var n in t)
    W(t, n) && (e[n] = t[n]);
  return e;
}
const or = [].concat;
function Kn(t) {
  return or.apply([], t);
}
const On = "Boolean,String,Date,RegExp,Blob,File,FileList,FileSystemFileHandle,ArrayBuffer,DataView,Uint8ClampedArray,ImageBitmap,ImageData,Map,Set,CryptoKey".split(",").concat(Kn([8, 16, 32, 64].map((t) => ["Int", "Uint", "Float"].map((e) => e + t + "Array")))).filter((t) => I[t]), ar = On.map((t) => I[t]);
An(On, (t) => [t, !0]);
let re = null;
function We(t) {
  re = typeof WeakMap < "u" && /* @__PURE__ */ new WeakMap();
  const e = At(t);
  return re = null, e;
}
function At(t) {
  if (!t || typeof t != "object")
    return t;
  let e = re && re.get(t);
  if (e)
    return e;
  if (M(t)) {
    e = [], re && re.set(t, e);
    for (var n = 0, r = t.length; n < r; ++n)
      e.push(At(t[n]));
  } else if (ar.indexOf(t.constructor) >= 0)
    e = t;
  else {
    const i = Fe(t);
    for (var s in e = i === Object.prototype ? {} : Object.create(i), re && re.set(t, e), t)
      W(t, s) && (e[s] = At(t[s]));
  }
  return e;
}
const { toString: ur } = {};
function St(t) {
  return ur.call(t).slice(8, -1);
}
const Kt = typeof Symbol < "u" ? Symbol.iterator : "@@iterator", cr = typeof Kt == "symbol" ? function(t) {
  var e;
  return t != null && (e = t[Kt]) && e.apply(t);
} : function() {
  return null;
}, _e = {};
function J(t) {
  var e, n, r, s;
  if (arguments.length === 1) {
    if (M(t))
      return t.slice();
    if (this === _e && typeof t == "string")
      return [t];
    if (s = cr(t)) {
      for (n = []; !(r = s.next()).done; )
        n.push(r.value);
      return n;
    }
    if (t == null)
      return [t];
    if (typeof (e = t.length) == "number") {
      for (n = new Array(e); e--; )
        n[e] = t[e];
      return n;
    }
    return [t];
  }
  for (e = arguments.length, n = new Array(e); e--; )
    n[e] = arguments[e];
  return n;
}
const Xt = typeof Symbol < "u" ? (t) => t[Symbol.toStringTag] === "AsyncFunction" : () => !1;
var Y = typeof location < "u" && /^(http|https):\/\/(localhost|127\.0\.0\.1)/.test(location.href);
function Cn(t, e) {
  Y = t, In = e;
}
var In = () => !0;
const lr = !new Error("").stack;
function me() {
  if (lr)
    try {
      throw me.arguments, new Error();
    } catch (t) {
      return t;
    }
  return new Error();
}
function Ot(t, e) {
  var n = t.stack;
  return n ? (e = e || 0, n.indexOf(t.name) === 0 && (e += (t.name + t.message).split(`
`).length), n.split(`
`).slice(e).filter(In).map((r) => `
` + r).join("")) : "";
}
var jn = ["Unknown", "Constraint", "Data", "TransactionInactive", "ReadOnly", "Version", "NotFound", "InvalidState", "InvalidAccess", "Abort", "Timeout", "QuotaExceeded", "Syntax", "DataClone"], Zt = ["Modify", "Bulk", "OpenFailed", "VersionChange", "Schema", "Upgrade", "InvalidTable", "MissingAPI", "NoSuchDatabase", "InvalidArgument", "SubTransaction", "Unsupported", "Internal", "DatabaseClosed", "PrematureCommit", "ForeignAwait"].concat(jn), hr = { VersionChanged: "Database version changed by other database connection", DatabaseClosed: "Database has been closed", Abort: "Transaction aborted", TransactionInactive: "Transaction has already completed or failed", MissingAPI: "IndexedDB API missing. Please visit https://tinyurl.com/y2uuvskb" };
function xe(t, e) {
  this._e = me(), this.name = t, this.message = e;
}
function Tn(t, e) {
  return t + ". Errors: " + Object.keys(e).map((n) => e[n].toString()).filter((n, r, s) => s.indexOf(n) === r).join(`
`);
}
function ct(t, e, n, r) {
  this._e = me(), this.failures = e, this.failedKeys = r, this.successCount = n, this.message = Tn(t, e);
}
function Te(t, e) {
  this._e = me(), this.name = "BulkError", this.failures = Object.keys(e).map((n) => e[n]), this.failuresByPos = e, this.message = Tn(t, e);
}
ke(xe).from(Error).extend({ stack: { get: function() {
  return this._stack || (this._stack = this.name + ": " + this.message + Ot(this._e, 2));
} }, toString: function() {
  return this.name + ": " + this.message;
} }), ke(ct).from(xe), ke(Te).from(xe);
var en = Zt.reduce((t, e) => (t[e] = e + "Error", t), {});
const dr = xe;
var S = Zt.reduce((t, e) => {
  var n = e + "Error";
  function r(s, i) {
    this._e = me(), this.name = n, s ? typeof s == "string" ? (this.message = `${s}${i ? `
 ` + i : ""}`, this.inner = i || null) : typeof s == "object" && (this.message = `${s.name} ${s.message}`, this.inner = s) : (this.message = hr[e] || n, this.inner = null);
  }
  return ke(r).from(dr), t[e] = r, t;
}, {});
S.Syntax = SyntaxError, S.Type = TypeError, S.Range = RangeError;
var hn = jn.reduce((t, e) => (t[e + "Error"] = S[e], t), {}), tt = Zt.reduce((t, e) => (["Syntax", "Type", "Range"].indexOf(e) === -1 && (t[e + "Error"] = S[e]), t), {});
function C() {
}
function Me(t) {
  return t;
}
function fr(t, e) {
  return t == null || t === Me ? e : function(n) {
    return e(t(n));
  };
}
function pe(t, e) {
  return function() {
    t.apply(this, arguments), e.apply(this, arguments);
  };
}
function pr(t, e) {
  return t === C ? e : function() {
    var n = t.apply(this, arguments);
    n !== void 0 && (arguments[0] = n);
    var r = this.onsuccess, s = this.onerror;
    this.onsuccess = null, this.onerror = null;
    var i = e.apply(this, arguments);
    return r && (this.onsuccess = this.onsuccess ? pe(r, this.onsuccess) : r), s && (this.onerror = this.onerror ? pe(s, this.onerror) : s), i !== void 0 ? i : n;
  };
}
function yr(t, e) {
  return t === C ? e : function() {
    t.apply(this, arguments);
    var n = this.onsuccess, r = this.onerror;
    this.onsuccess = this.onerror = null, e.apply(this, arguments), n && (this.onsuccess = this.onsuccess ? pe(n, this.onsuccess) : n), r && (this.onerror = this.onerror ? pe(r, this.onerror) : r);
  };
}
function mr(t, e) {
  return t === C ? e : function(n) {
    var r = t.apply(this, arguments);
    U(n, r);
    var s = this.onsuccess, i = this.onerror;
    this.onsuccess = null, this.onerror = null;
    var o = e.apply(this, arguments);
    return s && (this.onsuccess = this.onsuccess ? pe(s, this.onsuccess) : s), i && (this.onerror = this.onerror ? pe(i, this.onerror) : i), r === void 0 ? o === void 0 ? void 0 : o : U(r, o);
  };
}
function gr(t, e) {
  return t === C ? e : function() {
    return e.apply(this, arguments) !== !1 && t.apply(this, arguments);
  };
}
function tn(t, e) {
  return t === C ? e : function() {
    var n = t.apply(this, arguments);
    if (n && typeof n.then == "function") {
      for (var r = this, s = arguments.length, i = new Array(s); s--; )
        i[s] = arguments[s];
      return n.then(function() {
        return e.apply(r, i);
      });
    }
    return e.apply(this, arguments);
  };
}
tt.ModifyError = ct, tt.DexieError = xe, tt.BulkError = Te;
var qe = {};
const Dn = 100, [Ct, lt, It] = typeof Promise > "u" ? [] : (() => {
  let t = Promise.resolve();
  if (typeof crypto > "u" || !crypto.subtle)
    return [t, Fe(t), t];
  const e = crypto.subtle.digest("SHA-512", new Uint8Array([0]));
  return [e, Fe(e), t];
})(), Bn = lt && lt.then, nt = Ct && Ct.constructor, nn = !!It;
var jt = !1, vr = It ? () => {
  It.then(Ye);
} : I.setImmediate ? setImmediate.bind(null, Ye) : I.MutationObserver ? () => {
  var t = document.createElement("div");
  new MutationObserver(() => {
    Ye(), t = null;
  }).observe(t, { attributes: !0 }), t.setAttribute("i", "1");
} : () => {
  setTimeout(Ye, 0);
}, De = function(t, e) {
  je.push([t, e]), ht && (vr(), ht = !1);
}, Tt = !0, ht = !0, he = [], rt = [], Dt = null, Bt = Me, Ee = { id: "global", global: !0, ref: 0, unhandleds: [], onunhandled: pn, pgp: !1, env: {}, finalize: function() {
  this.unhandleds.forEach((t) => {
    try {
      pn(t[0], t[1]);
    } catch {
    }
  });
} }, A = Ee, je = [], de = 0, st = [];
function E(t) {
  if (typeof this != "object")
    throw new TypeError("Promises must be constructed via new");
  this._listeners = [], this.onuncatched = C, this._lib = !1;
  var e = this._PSD = A;
  if (Y && (this._stackHolder = me(), this._prev = null, this._numPrev = 0), typeof t != "function") {
    if (t !== qe)
      throw new TypeError("Not a function");
    return this._state = arguments[1], this._value = arguments[2], void (this._state === !1 && Nt(this, this._value));
  }
  this._state = null, this._value = null, ++e.ref, Nn(this, t);
}
const Rt = { get: function() {
  var t = A, e = dt;
  function n(r, s) {
    var i = !t.global && (t !== A || e !== dt);
    const o = i && !te();
    var u = new E((a, d) => {
      rn(this, new Rn(ft(r, t, i, o), ft(s, t, i, o), a, d, t));
    });
    return Y && qn(u, this), u;
  }
  return n.prototype = qe, n;
}, set: function(t) {
  Z(this, "then", t && t.prototype === qe ? Rt : { get: function() {
    return t;
  }, set: Rt.set });
} };
function Rn(t, e, n, r, s) {
  this.onFulfilled = typeof t == "function" ? t : null, this.onRejected = typeof e == "function" ? e : null, this.resolve = n, this.reject = r, this.psd = s;
}
function Nn(t, e) {
  try {
    e((n) => {
      if (t._state === null) {
        if (n === t)
          throw new TypeError("A promise cannot be resolved with itself.");
        var r = t._lib && ze();
        n && typeof n.then == "function" ? Nn(t, (s, i) => {
          n instanceof E ? n._then(s, i) : n.then(s, i);
        }) : (t._state = !0, t._value = n, Fn(t)), r && He();
      }
    }, Nt.bind(null, t));
  } catch (n) {
    Nt(t, n);
  }
}
function Nt(t, e) {
  if (rt.push(e), t._state === null) {
    var n = t._lib && ze();
    e = Bt(e), t._state = !1, t._value = e, Y && e !== null && typeof e == "object" && !e._promise && function(r, s, i) {
      try {
        r.apply(null, i);
      } catch (o) {
        s && s(o);
      }
    }(() => {
      var r = Jt(e, "stack");
      e._promise = t, Z(e, "stack", { get: () => jt ? r && (r.get ? r.get.apply(e) : r.value) : t.stack });
    }), function(r) {
      he.some((s) => s._value === r._value) || he.push(r);
    }(t), Fn(t), n && He();
  }
}
function Fn(t) {
  var e = t._listeners;
  t._listeners = [];
  for (var n = 0, r = e.length; n < r; ++n)
    rn(t, e[n]);
  var s = t._PSD;
  --s.ref || s.finalize(), de === 0 && (++de, De(() => {
    --de == 0 && sn();
  }, []));
}
function rn(t, e) {
  if (t._state !== null) {
    var n = t._state ? e.onFulfilled : e.onRejected;
    if (n === null)
      return (t._state ? e.resolve : e.reject)(t._value);
    ++e.psd.ref, ++de, De(br, [n, t, e]);
  } else
    t._listeners.push(e);
}
function br(t, e, n) {
  try {
    Dt = e;
    var r, s = e._value;
    e._state ? r = t(s) : (rt.length && (rt = []), r = t(s), rt.indexOf(s) === -1 && function(i) {
      for (var o = he.length; o; )
        if (he[--o]._value === i._value)
          return void he.splice(o, 1);
    }(e)), n.resolve(r);
  } catch (i) {
    n.reject(i);
  } finally {
    Dt = null, --de == 0 && sn(), --n.psd.ref || n.psd.finalize();
  }
}
function Mn(t, e, n) {
  if (e.length === n)
    return e;
  var r = "";
  if (t._state === !1) {
    var s, i, o = t._value;
    o != null ? (s = o.name || "Error", i = o.message || o, r = Ot(o, 0)) : (s = o, i = ""), e.push(s + (i ? ": " + i : "") + r);
  }
  return Y && ((r = Ot(t._stackHolder, 2)) && e.indexOf(r) === -1 && e.push(r), t._prev && Mn(t._prev, e, n)), e;
}
function qn(t, e) {
  var n = e ? e._numPrev + 1 : 0;
  n < 100 && (t._prev = e, t._numPrev = n);
}
function Ye() {
  ze() && He();
}
function ze() {
  var t = Tt;
  return Tt = !1, ht = !1, t;
}
function He() {
  var t, e, n;
  do
    for (; je.length > 0; )
      for (t = je, je = [], n = t.length, e = 0; e < n; ++e) {
        var r = t[e];
        r[0].apply(null, r[1]);
      }
  while (je.length > 0);
  Tt = !0, ht = !0;
}
function sn() {
  var t = he;
  he = [], t.forEach((r) => {
    r._PSD.onunhandled.call(null, r._value, r);
  });
  for (var e = st.slice(0), n = e.length; n; )
    e[--n]();
}
function Ge(t) {
  return new E(qe, !1, t);
}
function j(t, e) {
  var n = A;
  return function() {
    var r = ze(), s = A;
    try {
      return oe(n, !0), t.apply(this, arguments);
    } catch (i) {
      e && e(i);
    } finally {
      oe(s, !1), r && He();
    }
  };
}
Pe(E.prototype, { then: Rt, _then: function(t, e) {
  rn(this, new Rn(null, null, t, e, A));
}, catch: function(t) {
  if (arguments.length === 1)
    return this.then(null, t);
  var e = arguments[0], n = arguments[1];
  return typeof e == "function" ? this.then(null, (r) => r instanceof e ? n(r) : Ge(r)) : this.then(null, (r) => r && r.name === e ? n(r) : Ge(r));
}, finally: function(t) {
  return this.then((e) => (t(), e), (e) => (t(), Ge(e)));
}, stack: { get: function() {
  if (this._stack)
    return this._stack;
  try {
    jt = !0;
    var t = Mn(this, [], 20).join(`
From previous: `);
    return this._state !== null && (this._stack = t), t;
  } finally {
    jt = !1;
  }
} }, timeout: function(t, e) {
  return t < 1 / 0 ? new E((n, r) => {
    var s = setTimeout(() => r(new S.Timeout(e)), t);
    this.then(n, r).finally(clearTimeout.bind(null, s));
  }) : this;
} }), typeof Symbol < "u" && Symbol.toStringTag && Z(E.prototype, Symbol.toStringTag, "Dexie.Promise"), Ee.env = $n(), Pe(E, { all: function() {
  var t = J.apply(null, arguments).map(Qe);
  return new E(function(e, n) {
    t.length === 0 && e([]);
    var r = t.length;
    t.forEach((s, i) => E.resolve(s).then((o) => {
      t[i] = o, --r || e(t);
    }, n));
  });
}, resolve: (t) => {
  if (t instanceof E)
    return t;
  if (t && typeof t.then == "function")
    return new E((n, r) => {
      t.then(n, r);
    });
  var e = new E(qe, !0, t);
  return qn(e, Dt), e;
}, reject: Ge, race: function() {
  var t = J.apply(null, arguments).map(Qe);
  return new E((e, n) => {
    t.map((r) => E.resolve(r).then(e, n));
  });
}, PSD: { get: () => A, set: (t) => A = t }, totalEchoes: { get: () => dt }, newPSD: ie, usePSD: Se, scheduler: { get: () => De, set: (t) => {
  De = t;
} }, rejectionMapper: { get: () => Bt, set: (t) => {
  Bt = t;
} }, follow: (t, e) => new E((n, r) => ie((s, i) => {
  var o = A;
  o.unhandleds = [], o.onunhandled = i, o.finalize = pe(function() {
    (function(u) {
      function a() {
        u(), st.splice(st.indexOf(a), 1);
      }
      st.push(a), ++de, De(() => {
        --de == 0 && sn();
      }, []);
    })(() => {
      this.unhandleds.length === 0 ? s() : i(this.unhandleds[0]);
    });
  }, o.finalize), t();
}, e, n, r)) }), nt && (nt.allSettled && Z(E, "allSettled", function() {
  const t = J.apply(null, arguments).map(Qe);
  return new E((e) => {
    t.length === 0 && e([]);
    let n = t.length;
    const r = new Array(n);
    t.forEach((s, i) => E.resolve(s).then((o) => r[i] = { status: "fulfilled", value: o }, (o) => r[i] = { status: "rejected", reason: o }).then(() => --n || e(r)));
  });
}), nt.any && typeof AggregateError < "u" && Z(E, "any", function() {
  const t = J.apply(null, arguments).map(Qe);
  return new E((e, n) => {
    t.length === 0 && n(new AggregateError([]));
    let r = t.length;
    const s = new Array(r);
    t.forEach((i, o) => E.resolve(i).then((u) => e(u), (u) => {
      s[o] = u, --r || n(new AggregateError(s));
    }));
  });
}));
const F = { awaits: 0, echoes: 0, id: 0 };
var wr = 0, it = [], bt = 0, dt = 0, _r = 0;
function ie(t, e, n, r) {
  var s = A, i = Object.create(s);
  i.parent = s, i.ref = 0, i.global = !1, i.id = ++_r;
  var o = Ee.env;
  i.env = nn ? { Promise: E, PromiseProp: { value: E, configurable: !0, writable: !0 }, all: E.all, race: E.race, allSettled: E.allSettled, any: E.any, resolve: E.resolve, reject: E.reject, nthen: dn(o.nthen, i), gthen: dn(o.gthen, i) } : {}, e && U(i, e), ++s.ref, i.finalize = function() {
    --this.parent.ref || this.parent.finalize();
  };
  var u = Se(i, t, n, r);
  return i.ref === 0 && i.finalize(), u;
}
function Ae() {
  return F.id || (F.id = ++wr), ++F.awaits, F.echoes += Dn, F.id;
}
function te() {
  return !!F.awaits && (--F.awaits == 0 && (F.id = 0), F.echoes = F.awaits * Dn, !0);
}
function Qe(t) {
  return F.echoes && t && t.constructor === nt ? (Ae(), t.then((e) => (te(), e), (e) => (te(), R(e)))) : t;
}
function kr(t) {
  ++dt, F.echoes && --F.echoes != 0 || (F.echoes = F.id = 0), it.push(A), oe(t, !0);
}
function xr() {
  var t = it[it.length - 1];
  it.pop(), oe(t, !1);
}
function oe(t, e) {
  var n = A;
  if ((e ? !F.echoes || bt++ && t === A : !bt || --bt && t === A) || Un(e ? kr.bind(null, t) : xr), t !== A && (A = t, n === Ee && (Ee.env = $n()), nn)) {
    var r = Ee.env.Promise, s = t.env;
    lt.then = s.nthen, r.prototype.then = s.gthen, (n.global || t.global) && (Object.defineProperty(I, "Promise", s.PromiseProp), r.all = s.all, r.race = s.race, r.resolve = s.resolve, r.reject = s.reject, s.allSettled && (r.allSettled = s.allSettled), s.any && (r.any = s.any));
  }
}
function $n() {
  var t = I.Promise;
  return nn ? { Promise: t, PromiseProp: Object.getOwnPropertyDescriptor(I, "Promise"), all: t.all, race: t.race, allSettled: t.allSettled, any: t.any, resolve: t.resolve, reject: t.reject, nthen: lt.then, gthen: t.prototype.then } : {};
}
function Se(t, e, n, r, s) {
  var i = A;
  try {
    return oe(t, !0), e(n, r, s);
  } finally {
    oe(i, !1);
  }
}
function Un(t) {
  Bn.call(Ct, t);
}
function ft(t, e, n, r) {
  return typeof t != "function" ? t : function() {
    var s = A;
    n && Ae(), oe(e, !0);
    try {
      return t.apply(this, arguments);
    } finally {
      oe(s, !1), r && Un(te);
    }
  };
}
function dn(t, e) {
  return function(n, r) {
    return t.call(this, ft(n, e), ft(r, e));
  };
}
("" + Bn).indexOf("[native code]") === -1 && (Ae = te = C);
const fn = "unhandledrejection";
function pn(t, e) {
  var n;
  try {
    n = e.onuncatched(t);
  } catch {
  }
  if (n !== !1)
    try {
      var r, s = { promise: e, reason: t };
      if (I.document && document.createEvent ? ((r = document.createEvent("Event")).initEvent(fn, !0, !0), U(r, s)) : I.CustomEvent && U(r = new CustomEvent(fn, { detail: s }), s), r && I.dispatchEvent && (dispatchEvent(r), !I.PromiseRejectionEvent && I.onunhandledrejection))
        try {
          I.onunhandledrejection(r);
        } catch {
        }
      Y && r && !r.defaultPrevented && console.warn(`Unhandled rejection: ${t.stack || t}`);
    } catch {
    }
}
var R = E.reject;
function Ft(t, e, n, r) {
  if (t.idbdb && (t._state.openComplete || A.letThrough || t._vip)) {
    var s = t._createTransaction(e, n, t._dbSchema);
    try {
      s.create(), t._state.PR1398_maxLoop = 3;
    } catch (i) {
      return i.name === en.InvalidState && t.isOpen() && --t._state.PR1398_maxLoop > 0 ? (console.warn("Dexie: Need to reopen db"), t._close(), t.open().then(() => Ft(t, e, n, r))) : R(i);
    }
    return s._promise(e, (i, o) => ie(() => (A.trans = s, r(i, o, s)))).then((i) => s._completion.then(() => i));
  }
  if (t._state.openComplete)
    return R(new S.DatabaseClosed(t._state.dbOpenError));
  if (!t._state.isBeingOpened) {
    if (!t._options.autoOpen)
      return R(new S.DatabaseClosed());
    t.open().catch(C);
  }
  return t._state.dbReadyPromise.then(() => Ft(t, e, n, r));
}
const yn = "3.2.4", le = String.fromCharCode(65535), Mt = -1 / 0, G = "Invalid key provided. Keys must be of type string, number, Date or Array<string | number | Date>.", Vn = "String expected.", Be = [], mt = typeof navigator < "u" && /(MSIE|Trident|Edge)/.test(navigator.userAgent), Er = mt, Pr = mt, Ln = (t) => !/(dexie\.js|dexie\.min\.js)/.test(t), gt = "__dbnames", wt = "readonly", _t = "readwrite";
function ye(t, e) {
  return t ? e ? function() {
    return t.apply(this, arguments) && e.apply(this, arguments);
  } : t : e;
}
const Wn = { type: 3, lower: -1 / 0, lowerOpen: !1, upper: [[]], upperOpen: !1 };
function Je(t) {
  return typeof t != "string" || /\./.test(t) ? (e) => e : (e) => (e[t] === void 0 && t in e && delete (e = We(e))[t], e);
}
class Ar {
  _trans(e, n, r) {
    const s = this._tx || A.trans, i = this.name;
    function o(a, d, c) {
      if (!c.schema[i])
        throw new S.NotFound("Table " + i + " not part of transaction");
      return n(c.idbtrans, c);
    }
    const u = ze();
    try {
      return s && s.db === this.db ? s === A.trans ? s._promise(e, o, r) : ie(() => s._promise(e, o, r), { trans: s, transless: A.transless || A }) : Ft(this.db, e, [this.name], o);
    } finally {
      u && He();
    }
  }
  get(e, n) {
    return e && e.constructor === Object ? this.where(e).first(n) : this._trans("readonly", (r) => this.core.get({ trans: r, key: e }).then((s) => this.hook.reading.fire(s))).then(n);
  }
  where(e) {
    if (typeof e == "string")
      return new this.db.WhereClause(this, e);
    if (M(e))
      return new this.db.WhereClause(this, `[${e.join("+")}]`);
    const n = B(e);
    if (n.length === 1)
      return this.where(n[0]).equals(e[n[0]]);
    const r = this.schema.indexes.concat(this.schema.primKey).filter((d) => d.compound && n.every((c) => d.keyPath.indexOf(c) >= 0) && d.keyPath.every((c) => n.indexOf(c) >= 0))[0];
    if (r && this.db._maxKey !== le)
      return this.where(r.name).equals(r.keyPath.map((d) => e[d]));
    !r && Y && console.warn(`The query ${JSON.stringify(e)} on ${this.name} would benefit of a compound index [${n.join("+")}]`);
    const { idxByName: s } = this.schema, i = this.db._deps.indexedDB;
    function o(d, c) {
      try {
        return i.cmp(d, c) === 0;
      } catch {
        return !1;
      }
    }
    const [u, a] = n.reduce(([d, c], l) => {
      const h = s[l], p = e[l];
      return [d || h, d || !h ? ye(c, h && h.multi ? (g) => {
        const f = ee(g, l);
        return M(f) && f.some((v) => o(p, v));
      } : (g) => o(p, ee(g, l))) : c];
    }, [null, null]);
    return u ? this.where(u.name).equals(e[u.keyPath]).filter(a) : r ? this.filter(a) : this.where(n).equals("");
  }
  filter(e) {
    return this.toCollection().and(e);
  }
  count(e) {
    return this.toCollection().count(e);
  }
  offset(e) {
    return this.toCollection().offset(e);
  }
  limit(e) {
    return this.toCollection().limit(e);
  }
  each(e) {
    return this.toCollection().each(e);
  }
  toArray(e) {
    return this.toCollection().toArray(e);
  }
  toCollection() {
    return new this.db.Collection(new this.db.WhereClause(this));
  }
  orderBy(e) {
    return new this.db.Collection(new this.db.WhereClause(this, M(e) ? `[${e.join("+")}]` : e));
  }
  reverse() {
    return this.toCollection().reverse();
  }
  mapToClass(e) {
    this.schema.mappedClass = e;
    const n = (r) => {
      if (!r)
        return r;
      const s = Object.create(e.prototype);
      for (var i in r)
        if (W(r, i))
          try {
            s[i] = r[i];
          } catch {
          }
      return s;
    };
    return this.schema.readHook && this.hook.reading.unsubscribe(this.schema.readHook), this.schema.readHook = n, this.hook("reading", n), e;
  }
  defineClass() {
    return this.mapToClass(function(e) {
      U(this, e);
    });
  }
  add(e, n) {
    const { auto: r, keyPath: s } = this.schema.primKey;
    let i = e;
    return s && r && (i = Je(s)(e)), this._trans("readwrite", (o) => this.core.mutate({ trans: o, type: "add", keys: n != null ? [n] : null, values: [i] })).then((o) => o.numFailures ? E.reject(o.failures[0]) : o.lastResult).then((o) => {
      if (s)
        try {
          z(e, s, o);
        } catch {
        }
      return o;
    });
  }
  update(e, n) {
    if (typeof e != "object" || M(e))
      return this.where(":id").equals(e).modify(n);
    {
      const r = ee(e, this.schema.primKey.keyPath);
      if (r === void 0)
        return R(new S.InvalidArgument("Given object does not contain its primary key"));
      try {
        typeof n != "function" ? B(n).forEach((s) => {
          z(e, s, n[s]);
        }) : n(e, { value: e, primKey: r });
      } catch {
      }
      return this.where(":id").equals(r).modify(n);
    }
  }
  put(e, n) {
    const { auto: r, keyPath: s } = this.schema.primKey;
    let i = e;
    return s && r && (i = Je(s)(e)), this._trans("readwrite", (o) => this.core.mutate({ trans: o, type: "put", values: [i], keys: n != null ? [n] : null })).then((o) => o.numFailures ? E.reject(o.failures[0]) : o.lastResult).then((o) => {
      if (s)
        try {
          z(e, s, o);
        } catch {
        }
      return o;
    });
  }
  delete(e) {
    return this._trans("readwrite", (n) => this.core.mutate({ trans: n, type: "delete", keys: [e] })).then((n) => n.numFailures ? E.reject(n.failures[0]) : void 0);
  }
  clear() {
    return this._trans("readwrite", (e) => this.core.mutate({ trans: e, type: "deleteRange", range: Wn })).then((e) => e.numFailures ? E.reject(e.failures[0]) : void 0);
  }
  bulkGet(e) {
    return this._trans("readonly", (n) => this.core.getMany({ keys: e, trans: n }).then((r) => r.map((s) => this.hook.reading.fire(s))));
  }
  bulkAdd(e, n, r) {
    const s = Array.isArray(n) ? n : void 0, i = (r = r || (s ? void 0 : n)) ? r.allKeys : void 0;
    return this._trans("readwrite", (o) => {
      const { auto: u, keyPath: a } = this.schema.primKey;
      if (a && s)
        throw new S.InvalidArgument("bulkAdd(): keys argument invalid on tables with inbound keys");
      if (s && s.length !== e.length)
        throw new S.InvalidArgument("Arguments objects and keys must have the same length");
      const d = e.length;
      let c = a && u ? e.map(Je(a)) : e;
      return this.core.mutate({ trans: o, type: "add", keys: s, values: c, wantResults: i }).then(({ numFailures: l, results: h, lastResult: p, failures: g }) => {
        if (l === 0)
          return i ? h : p;
        throw new Te(`${this.name}.bulkAdd(): ${l} of ${d} operations failed`, g);
      });
    });
  }
  bulkPut(e, n, r) {
    const s = Array.isArray(n) ? n : void 0, i = (r = r || (s ? void 0 : n)) ? r.allKeys : void 0;
    return this._trans("readwrite", (o) => {
      const { auto: u, keyPath: a } = this.schema.primKey;
      if (a && s)
        throw new S.InvalidArgument("bulkPut(): keys argument invalid on tables with inbound keys");
      if (s && s.length !== e.length)
        throw new S.InvalidArgument("Arguments objects and keys must have the same length");
      const d = e.length;
      let c = a && u ? e.map(Je(a)) : e;
      return this.core.mutate({ trans: o, type: "put", keys: s, values: c, wantResults: i }).then(({ numFailures: l, results: h, lastResult: p, failures: g }) => {
        if (l === 0)
          return i ? h : p;
        throw new Te(`${this.name}.bulkPut(): ${l} of ${d} operations failed`, g);
      });
    });
  }
  bulkDelete(e) {
    const n = e.length;
    return this._trans("readwrite", (r) => this.core.mutate({ trans: r, type: "delete", keys: e })).then(({ numFailures: r, lastResult: s, failures: i }) => {
      if (r === 0)
        return s;
      throw new Te(`${this.name}.bulkDelete(): ${r} of ${n} operations failed`, i);
    });
  }
}
function Re(t) {
  var e = {}, n = function(o, u) {
    if (u) {
      for (var a = arguments.length, d = new Array(a - 1); --a; )
        d[a - 1] = arguments[a];
      return e[o].subscribe.apply(null, d), t;
    }
    if (typeof o == "string")
      return e[o];
  };
  n.addEventType = i;
  for (var r = 1, s = arguments.length; r < s; ++r)
    i(arguments[r]);
  return n;
  function i(o, u, a) {
    if (typeof o != "object") {
      var d;
      u || (u = gr), a || (a = C);
      var c = { subscribers: [], fire: a, subscribe: function(l) {
        c.subscribers.indexOf(l) === -1 && (c.subscribers.push(l), c.fire = u(c.fire, l));
      }, unsubscribe: function(l) {
        c.subscribers = c.subscribers.filter(function(h) {
          return h !== l;
        }), c.fire = c.subscribers.reduce(u, a);
      } };
      return e[o] = n[o] = c, c;
    }
    B(d = o).forEach(function(l) {
      var h = d[l];
      if (M(h))
        i(l, d[l][0], d[l][1]);
      else {
        if (h !== "asap")
          throw new S.InvalidArgument("Invalid event config");
        var p = i(l, Me, function() {
          for (var g = arguments.length, f = new Array(g); g--; )
            f[g] = arguments[g];
          p.subscribers.forEach(function(v) {
            Pn(function() {
              v.apply(null, f);
            });
          });
        });
      }
    });
  }
}
function Ce(t, e) {
  return ke(e).from({ prototype: t }), e;
}
function be(t, e) {
  return !(t.filter || t.algorithm || t.or) && (e ? t.justLimit : !t.replayFilter);
}
function kt(t, e) {
  t.filter = ye(t.filter, e);
}
function xt(t, e, n) {
  var r = t.replayFilter;
  t.replayFilter = r ? () => ye(r(), e()) : e, t.justLimit = n && !r;
}
function ot(t, e) {
  if (t.isPrimKey)
    return e.primaryKey;
  const n = e.getIndexByKeyPath(t.index);
  if (!n)
    throw new S.Schema("KeyPath " + t.index + " on object store " + e.name + " is not indexed");
  return n;
}
function mn(t, e, n) {
  const r = ot(t, e.schema);
  return e.openCursor({ trans: n, values: !t.keysOnly, reverse: t.dir === "prev", unique: !!t.unique, query: { index: r, range: t.range } });
}
function Xe(t, e, n, r) {
  const s = t.replayFilter ? ye(t.filter, t.replayFilter()) : t.filter;
  if (t.or) {
    const i = {}, o = (u, a, d) => {
      if (!s || s(a, d, (h) => a.stop(h), (h) => a.fail(h))) {
        var c = a.primaryKey, l = "" + c;
        l === "[object ArrayBuffer]" && (l = "" + new Uint8Array(c)), W(i, l) || (i[l] = !0, e(u, a, d));
      }
    };
    return Promise.all([t.or._iterate(o, n), gn(mn(t, r, n), t.algorithm, o, !t.keysOnly && t.valueMapper)]);
  }
  return gn(mn(t, r, n), ye(t.algorithm, s), e, !t.keysOnly && t.valueMapper);
}
function gn(t, e, n, r) {
  var s = j(r ? (i, o, u) => n(r(i), o, u) : n);
  return t.then((i) => {
    if (i)
      return i.start(() => {
        var o = () => i.continue();
        e && !e(i, (u) => o = u, (u) => {
          i.stop(u), o = C;
        }, (u) => {
          i.fail(u), o = C;
        }) || s(i.value, i, (u) => o = u), o();
      });
  });
}
function $(t, e) {
  try {
    const n = vn(t), r = vn(e);
    if (n !== r)
      return n === "Array" ? 1 : r === "Array" ? -1 : n === "binary" ? 1 : r === "binary" ? -1 : n === "string" ? 1 : r === "string" ? -1 : n === "Date" ? 1 : r !== "Date" ? NaN : -1;
    switch (n) {
      case "number":
      case "Date":
      case "string":
        return t > e ? 1 : t < e ? -1 : 0;
      case "binary":
        return function(s, i) {
          const o = s.length, u = i.length, a = o < u ? o : u;
          for (let d = 0; d < a; ++d)
            if (s[d] !== i[d])
              return s[d] < i[d] ? -1 : 1;
          return o === u ? 0 : o < u ? -1 : 1;
        }(bn(t), bn(e));
      case "Array":
        return function(s, i) {
          const o = s.length, u = i.length, a = o < u ? o : u;
          for (let d = 0; d < a; ++d) {
            const c = $(s[d], i[d]);
            if (c !== 0)
              return c;
          }
          return o === u ? 0 : o < u ? -1 : 1;
        }(t, e);
    }
  } catch {
  }
  return NaN;
}
function vn(t) {
  const e = typeof t;
  if (e !== "object")
    return e;
  if (ArrayBuffer.isView(t))
    return "binary";
  const n = St(t);
  return n === "ArrayBuffer" ? "binary" : n;
}
function bn(t) {
  return t instanceof Uint8Array ? t : ArrayBuffer.isView(t) ? new Uint8Array(t.buffer, t.byteOffset, t.byteLength) : new Uint8Array(t);
}
class Sr {
  _read(e, n) {
    var r = this._ctx;
    return r.error ? r.table._trans(null, R.bind(null, r.error)) : r.table._trans("readonly", e).then(n);
  }
  _write(e) {
    var n = this._ctx;
    return n.error ? n.table._trans(null, R.bind(null, n.error)) : n.table._trans("readwrite", e, "locked");
  }
  _addAlgorithm(e) {
    var n = this._ctx;
    n.algorithm = ye(n.algorithm, e);
  }
  _iterate(e, n) {
    return Xe(this._ctx, e, n, this._ctx.table.core);
  }
  clone(e) {
    var n = Object.create(this.constructor.prototype), r = Object.create(this._ctx);
    return e && U(r, e), n._ctx = r, n;
  }
  raw() {
    return this._ctx.valueMapper = null, this;
  }
  each(e) {
    var n = this._ctx;
    return this._read((r) => Xe(n, e, r, n.table.core));
  }
  count(e) {
    return this._read((n) => {
      const r = this._ctx, s = r.table.core;
      if (be(r, !0))
        return s.count({ trans: n, query: { index: ot(r, s.schema), range: r.range } }).then((o) => Math.min(o, r.limit));
      var i = 0;
      return Xe(r, () => (++i, !1), n, s).then(() => i);
    }).then(e);
  }
  sortBy(e, n) {
    const r = e.split(".").reverse(), s = r[0], i = r.length - 1;
    function o(d, c) {
      return c ? o(d[r[c]], c - 1) : d[s];
    }
    var u = this._ctx.dir === "next" ? 1 : -1;
    function a(d, c) {
      var l = o(d, i), h = o(c, i);
      return l < h ? -u : l > h ? u : 0;
    }
    return this.toArray(function(d) {
      return d.sort(a);
    }).then(n);
  }
  toArray(e) {
    return this._read((n) => {
      var r = this._ctx;
      if (r.dir === "next" && be(r, !0) && r.limit > 0) {
        const { valueMapper: s } = r, i = ot(r, r.table.core.schema);
        return r.table.core.query({ trans: n, limit: r.limit, values: !0, query: { index: i, range: r.range } }).then(({ result: o }) => s ? o.map(s) : o);
      }
      {
        const s = [];
        return Xe(r, (i) => s.push(i), n, r.table.core).then(() => s);
      }
    }, e);
  }
  offset(e) {
    var n = this._ctx;
    return e <= 0 || (n.offset += e, be(n) ? xt(n, () => {
      var r = e;
      return (s, i) => r === 0 || (r === 1 ? (--r, !1) : (i(() => {
        s.advance(r), r = 0;
      }), !1));
    }) : xt(n, () => {
      var r = e;
      return () => --r < 0;
    })), this;
  }
  limit(e) {
    return this._ctx.limit = Math.min(this._ctx.limit, e), xt(this._ctx, () => {
      var n = e;
      return function(r, s, i) {
        return --n <= 0 && s(i), n >= 0;
      };
    }, !0), this;
  }
  until(e, n) {
    return kt(this._ctx, function(r, s, i) {
      return !e(r.value) || (s(i), n);
    }), this;
  }
  first(e) {
    return this.limit(1).toArray(function(n) {
      return n[0];
    }).then(e);
  }
  last(e) {
    return this.reverse().first(e);
  }
  filter(e) {
    var n, r;
    return kt(this._ctx, function(s) {
      return e(s.value);
    }), n = this._ctx, r = e, n.isMatch = ye(n.isMatch, r), this;
  }
  and(e) {
    return this.filter(e);
  }
  or(e) {
    return new this.db.WhereClause(this._ctx.table, e, this);
  }
  reverse() {
    return this._ctx.dir = this._ctx.dir === "prev" ? "next" : "prev", this._ondirectionchange && this._ondirectionchange(this._ctx.dir), this;
  }
  desc() {
    return this.reverse();
  }
  eachKey(e) {
    var n = this._ctx;
    return n.keysOnly = !n.isMatch, this.each(function(r, s) {
      e(s.key, s);
    });
  }
  eachUniqueKey(e) {
    return this._ctx.unique = "unique", this.eachKey(e);
  }
  eachPrimaryKey(e) {
    var n = this._ctx;
    return n.keysOnly = !n.isMatch, this.each(function(r, s) {
      e(s.primaryKey, s);
    });
  }
  keys(e) {
    var n = this._ctx;
    n.keysOnly = !n.isMatch;
    var r = [];
    return this.each(function(s, i) {
      r.push(i.key);
    }).then(function() {
      return r;
    }).then(e);
  }
  primaryKeys(e) {
    var n = this._ctx;
    if (n.dir === "next" && be(n, !0) && n.limit > 0)
      return this._read((s) => {
        var i = ot(n, n.table.core.schema);
        return n.table.core.query({ trans: s, values: !1, limit: n.limit, query: { index: i, range: n.range } });
      }).then(({ result: s }) => s).then(e);
    n.keysOnly = !n.isMatch;
    var r = [];
    return this.each(function(s, i) {
      r.push(i.primaryKey);
    }).then(function() {
      return r;
    }).then(e);
  }
  uniqueKeys(e) {
    return this._ctx.unique = "unique", this.keys(e);
  }
  firstKey(e) {
    return this.limit(1).keys(function(n) {
      return n[0];
    }).then(e);
  }
  lastKey(e) {
    return this.reverse().firstKey(e);
  }
  distinct() {
    var e = this._ctx, n = e.index && e.table.schema.idxByName[e.index];
    if (!n || !n.multi)
      return this;
    var r = {};
    return kt(this._ctx, function(s) {
      var i = s.primaryKey.toString(), o = W(r, i);
      return r[i] = !0, !o;
    }), this;
  }
  modify(e) {
    var n = this._ctx;
    return this._write((r) => {
      var s;
      if (typeof e == "function")
        s = e;
      else {
        var i = B(e), o = i.length;
        s = function(f) {
          for (var v = !1, m = 0; m < o; ++m) {
            var y = i[m], w = e[y];
            ee(f, y) !== w && (z(f, y, w), v = !0);
          }
          return v;
        };
      }
      const u = n.table.core, { outbound: a, extractKey: d } = u.schema.primaryKey, c = this.db._options.modifyChunkSize || 200, l = [];
      let h = 0;
      const p = [], g = (f, v) => {
        const { failures: m, numFailures: y } = v;
        h += f - y;
        for (let w of B(m))
          l.push(m[w]);
      };
      return this.clone().primaryKeys().then((f) => {
        const v = (m) => {
          const y = Math.min(c, f.length - m);
          return u.getMany({ trans: r, keys: f.slice(m, m + y), cache: "immutable" }).then((w) => {
            const P = [], x = [], k = a ? [] : null, b = [];
            for (let _ = 0; _ < y; ++_) {
              const T = w[_], O = { value: We(T), primKey: f[m + _] };
              s.call(O, O.value, O) !== !1 && (O.value == null ? b.push(f[m + _]) : a || $(d(T), d(O.value)) === 0 ? (x.push(O.value), a && k.push(f[m + _])) : (b.push(f[m + _]), P.push(O.value)));
            }
            const K = be(n) && n.limit === 1 / 0 && (typeof e != "function" || e === Et) && { index: n.index, range: n.range };
            return Promise.resolve(P.length > 0 && u.mutate({ trans: r, type: "add", values: P }).then((_) => {
              for (let T in _.failures)
                b.splice(parseInt(T), 1);
              g(P.length, _);
            })).then(() => (x.length > 0 || K && typeof e == "object") && u.mutate({ trans: r, type: "put", keys: k, values: x, criteria: K, changeSpec: typeof e != "function" && e }).then((_) => g(x.length, _))).then(() => (b.length > 0 || K && e === Et) && u.mutate({ trans: r, type: "delete", keys: b, criteria: K }).then((_) => g(b.length, _))).then(() => f.length > m + y && v(m + c));
          });
        };
        return v(0).then(() => {
          if (l.length > 0)
            throw new ct("Error modifying one or more objects", l, h, p);
          return f.length;
        });
      });
    });
  }
  delete() {
    var e = this._ctx, n = e.range;
    return be(e) && (e.isPrimKey && !Pr || n.type === 3) ? this._write((r) => {
      const { primaryKey: s } = e.table.core.schema, i = n;
      return e.table.core.count({ trans: r, query: { index: s, range: i } }).then((o) => e.table.core.mutate({ trans: r, type: "deleteRange", range: i }).then(({ failures: u, lastResult: a, results: d, numFailures: c }) => {
        if (c)
          throw new ct("Could not delete some values", Object.keys(u).map((l) => u[l]), o - c);
        return o - c;
      }));
    }) : this.modify(Et);
  }
}
const Et = (t, e) => e.value = null;
function Kr(t, e) {
  return t < e ? -1 : t === e ? 0 : 1;
}
function Or(t, e) {
  return t > e ? -1 : t === e ? 0 : 1;
}
function L(t, e, n) {
  var r = t instanceof Hn ? new t.Collection(t) : t;
  return r._ctx.error = n ? new n(e) : new TypeError(e), r;
}
function we(t) {
  return new t.Collection(t, () => zn("")).limit(0);
}
function Cr(t, e, n, r, s, i) {
  for (var o = Math.min(t.length, r.length), u = -1, a = 0; a < o; ++a) {
    var d = e[a];
    if (d !== r[a])
      return s(t[a], n[a]) < 0 ? t.substr(0, a) + n[a] + n.substr(a + 1) : s(t[a], r[a]) < 0 ? t.substr(0, a) + r[a] + n.substr(a + 1) : u >= 0 ? t.substr(0, u) + e[u] + n.substr(u + 1) : null;
    s(t[a], d) < 0 && (u = a);
  }
  return o < r.length && i === "next" ? t + n.substr(t.length) : o < t.length && i === "prev" ? t.substr(0, n.length) : u < 0 ? null : t.substr(0, u) + r[u] + n.substr(u + 1);
}
function Ze(t, e, n, r) {
  var s, i, o, u, a, d, c, l = n.length;
  if (!n.every((f) => typeof f == "string"))
    return L(t, Vn);
  function h(f) {
    s = function(m) {
      return m === "next" ? (y) => y.toUpperCase() : (y) => y.toLowerCase();
    }(f), i = function(m) {
      return m === "next" ? (y) => y.toLowerCase() : (y) => y.toUpperCase();
    }(f), o = f === "next" ? Kr : Or;
    var v = n.map(function(m) {
      return { lower: i(m), upper: s(m) };
    }).sort(function(m, y) {
      return o(m.lower, y.lower);
    });
    u = v.map(function(m) {
      return m.upper;
    }), a = v.map(function(m) {
      return m.lower;
    }), d = f, c = f === "next" ? "" : r;
  }
  h("next");
  var p = new t.Collection(t, () => ne(u[0], a[l - 1] + r));
  p._ondirectionchange = function(f) {
    h(f);
  };
  var g = 0;
  return p._addAlgorithm(function(f, v, m) {
    var y = f.key;
    if (typeof y != "string")
      return !1;
    var w = i(y);
    if (e(w, a, g))
      return !0;
    for (var P = null, x = g; x < l; ++x) {
      var k = Cr(y, w, u[x], a[x], o, d);
      k === null && P === null ? g = x + 1 : (P === null || o(P, k) > 0) && (P = k);
    }
    return v(P !== null ? function() {
      f.continue(P + c);
    } : m), !1;
  }), p;
}
function ne(t, e, n, r) {
  return { type: 2, lower: t, upper: e, lowerOpen: n, upperOpen: r };
}
function zn(t) {
  return { type: 1, lower: t, upper: t };
}
class Hn {
  get Collection() {
    return this._ctx.table.db.Collection;
  }
  between(e, n, r, s) {
    r = r !== !1, s = s === !0;
    try {
      return this._cmp(e, n) > 0 || this._cmp(e, n) === 0 && (r || s) && (!r || !s) ? we(this) : new this.Collection(this, () => ne(e, n, !r, !s));
    } catch {
      return L(this, G);
    }
  }
  equals(e) {
    return e == null ? L(this, G) : new this.Collection(this, () => zn(e));
  }
  above(e) {
    return e == null ? L(this, G) : new this.Collection(this, () => ne(e, void 0, !0));
  }
  aboveOrEqual(e) {
    return e == null ? L(this, G) : new this.Collection(this, () => ne(e, void 0, !1));
  }
  below(e) {
    return e == null ? L(this, G) : new this.Collection(this, () => ne(void 0, e, !1, !0));
  }
  belowOrEqual(e) {
    return e == null ? L(this, G) : new this.Collection(this, () => ne(void 0, e));
  }
  startsWith(e) {
    return typeof e != "string" ? L(this, Vn) : this.between(e, e + le, !0, !0);
  }
  startsWithIgnoreCase(e) {
    return e === "" ? this.startsWith(e) : Ze(this, (n, r) => n.indexOf(r[0]) === 0, [e], le);
  }
  equalsIgnoreCase(e) {
    return Ze(this, (n, r) => n === r[0], [e], "");
  }
  anyOfIgnoreCase() {
    var e = J.apply(_e, arguments);
    return e.length === 0 ? we(this) : Ze(this, (n, r) => r.indexOf(n) !== -1, e, "");
  }
  startsWithAnyOfIgnoreCase() {
    var e = J.apply(_e, arguments);
    return e.length === 0 ? we(this) : Ze(this, (n, r) => r.some((s) => n.indexOf(s) === 0), e, le);
  }
  anyOf() {
    const e = J.apply(_e, arguments);
    let n = this._cmp;
    try {
      e.sort(n);
    } catch {
      return L(this, G);
    }
    if (e.length === 0)
      return we(this);
    const r = new this.Collection(this, () => ne(e[0], e[e.length - 1]));
    r._ondirectionchange = (i) => {
      n = i === "next" ? this._ascending : this._descending, e.sort(n);
    };
    let s = 0;
    return r._addAlgorithm((i, o, u) => {
      const a = i.key;
      for (; n(a, e[s]) > 0; )
        if (++s, s === e.length)
          return o(u), !1;
      return n(a, e[s]) === 0 || (o(() => {
        i.continue(e[s]);
      }), !1);
    }), r;
  }
  notEqual(e) {
    return this.inAnyRange([[Mt, e], [e, this.db._maxKey]], { includeLowers: !1, includeUppers: !1 });
  }
  noneOf() {
    const e = J.apply(_e, arguments);
    if (e.length === 0)
      return new this.Collection(this);
    try {
      e.sort(this._ascending);
    } catch {
      return L(this, G);
    }
    const n = e.reduce((r, s) => r ? r.concat([[r[r.length - 1][1], s]]) : [[Mt, s]], null);
    return n.push([e[e.length - 1], this.db._maxKey]), this.inAnyRange(n, { includeLowers: !1, includeUppers: !1 });
  }
  inAnyRange(e, n) {
    const r = this._cmp, s = this._ascending, i = this._descending, o = this._min, u = this._max;
    if (e.length === 0)
      return we(this);
    if (!e.every((y) => y[0] !== void 0 && y[1] !== void 0 && s(y[0], y[1]) <= 0))
      return L(this, "First argument to inAnyRange() must be an Array of two-value Arrays [lower,upper] where upper must not be lower than lower", S.InvalidArgument);
    const a = !n || n.includeLowers !== !1, d = n && n.includeUppers === !0;
    let c, l = s;
    function h(y, w) {
      return l(y[0], w[0]);
    }
    try {
      c = e.reduce(function(y, w) {
        let P = 0, x = y.length;
        for (; P < x; ++P) {
          const k = y[P];
          if (r(w[0], k[1]) < 0 && r(w[1], k[0]) > 0) {
            k[0] = o(k[0], w[0]), k[1] = u(k[1], w[1]);
            break;
          }
        }
        return P === x && y.push(w), y;
      }, []), c.sort(h);
    } catch {
      return L(this, G);
    }
    let p = 0;
    const g = d ? (y) => s(y, c[p][1]) > 0 : (y) => s(y, c[p][1]) >= 0, f = a ? (y) => i(y, c[p][0]) > 0 : (y) => i(y, c[p][0]) >= 0;
    let v = g;
    const m = new this.Collection(this, () => ne(c[0][0], c[c.length - 1][1], !a, !d));
    return m._ondirectionchange = (y) => {
      y === "next" ? (v = g, l = s) : (v = f, l = i), c.sort(h);
    }, m._addAlgorithm((y, w, P) => {
      for (var x = y.key; v(x); )
        if (++p, p === c.length)
          return w(P), !1;
      return !!function(k) {
        return !g(k) && !f(k);
      }(x) || (this._cmp(x, c[p][1]) === 0 || this._cmp(x, c[p][0]) === 0 || w(() => {
        l === s ? y.continue(c[p][0]) : y.continue(c[p][1]);
      }), !1);
    }), m;
  }
  startsWithAnyOf() {
    const e = J.apply(_e, arguments);
    return e.every((n) => typeof n == "string") ? e.length === 0 ? we(this) : this.inAnyRange(e.map((n) => [n, n + le])) : L(this, "startsWithAnyOf() only works with strings");
  }
}
function H(t) {
  return j(function(e) {
    return $e(e), t(e.target.error), !1;
  });
}
function $e(t) {
  t.stopPropagation && t.stopPropagation(), t.preventDefault && t.preventDefault();
}
const Ue = "storagemutated", se = "x-storagemutated-1", ae = Re(null, Ue);
class Ir {
  _lock() {
    return Ie(!A.global), ++this._reculock, this._reculock !== 1 || A.global || (A.lockOwnerFor = this), this;
  }
  _unlock() {
    if (Ie(!A.global), --this._reculock == 0)
      for (A.global || (A.lockOwnerFor = null); this._blockedFuncs.length > 0 && !this._locked(); ) {
        var e = this._blockedFuncs.shift();
        try {
          Se(e[1], e[0]);
        } catch {
        }
      }
    return this;
  }
  _locked() {
    return this._reculock && A.lockOwnerFor !== this;
  }
  create(e) {
    if (!this.mode)
      return this;
    const n = this.db.idbdb, r = this.db._state.dbOpenError;
    if (Ie(!this.idbtrans), !e && !n)
      switch (r && r.name) {
        case "DatabaseClosedError":
          throw new S.DatabaseClosed(r);
        case "MissingAPIError":
          throw new S.MissingAPI(r.message, r);
        default:
          throw new S.OpenFailed(r);
      }
    if (!this.active)
      throw new S.TransactionInactive();
    return Ie(this._completion._state === null), (e = this.idbtrans = e || (this.db.core ? this.db.core.transaction(this.storeNames, this.mode, { durability: this.chromeTransactionDurability }) : n.transaction(this.storeNames, this.mode, { durability: this.chromeTransactionDurability }))).onerror = j((s) => {
      $e(s), this._reject(e.error);
    }), e.onabort = j((s) => {
      $e(s), this.active && this._reject(new S.Abort(e.error)), this.active = !1, this.on("abort").fire(s);
    }), e.oncomplete = j(() => {
      this.active = !1, this._resolve(), "mutatedParts" in e && ae.storagemutated.fire(e.mutatedParts);
    }), this;
  }
  _promise(e, n, r) {
    if (e === "readwrite" && this.mode !== "readwrite")
      return R(new S.ReadOnly("Transaction is readonly"));
    if (!this.active)
      return R(new S.TransactionInactive());
    if (this._locked())
      return new E((i, o) => {
        this._blockedFuncs.push([() => {
          this._promise(e, n, r).then(i, o);
        }, A]);
      });
    if (r)
      return ie(() => {
        var i = new E((o, u) => {
          this._lock();
          const a = n(o, u, this);
          a && a.then && a.then(o, u);
        });
        return i.finally(() => this._unlock()), i._lib = !0, i;
      });
    var s = new E((i, o) => {
      var u = n(i, o, this);
      u && u.then && u.then(i, o);
    });
    return s._lib = !0, s;
  }
  _root() {
    return this.parent ? this.parent._root() : this;
  }
  waitFor(e) {
    var n = this._root();
    const r = E.resolve(e);
    if (n._waitingFor)
      n._waitingFor = n._waitingFor.then(() => r);
    else {
      n._waitingFor = r, n._waitingQueue = [];
      var s = n.idbtrans.objectStore(n.storeNames[0]);
      (function o() {
        for (++n._spinCount; n._waitingQueue.length; )
          n._waitingQueue.shift()();
        n._waitingFor && (s.get(-1 / 0).onsuccess = o);
      })();
    }
    var i = n._waitingFor;
    return new E((o, u) => {
      r.then((a) => n._waitingQueue.push(j(o.bind(null, a))), (a) => n._waitingQueue.push(j(u.bind(null, a)))).finally(() => {
        n._waitingFor === i && (n._waitingFor = null);
      });
    });
  }
  abort() {
    this.active && (this.active = !1, this.idbtrans && this.idbtrans.abort(), this._reject(new S.Abort()));
  }
  table(e) {
    const n = this._memoizedTables || (this._memoizedTables = {});
    if (W(n, e))
      return n[e];
    const r = this.schema[e];
    if (!r)
      throw new S.NotFound("Table " + e + " not part of transaction");
    const s = new this.db.Table(e, r, this);
    return s.core = this.db.core.table(e), n[e] = s, s;
  }
}
function qt(t, e, n, r, s, i, o) {
  return { name: t, keyPath: e, unique: n, multi: r, auto: s, compound: i, src: (n && !o ? "&" : "") + (r ? "*" : "") + (s ? "++" : "") + Yn(e) };
}
function Yn(t) {
  return typeof t == "string" ? t : t ? "[" + [].join.call(t, "+") + "]" : "";
}
function Gn(t, e, n) {
  return { name: t, primKey: e, indexes: n, mappedClass: null, idxByName: An(n, (r) => [r.name, r]) };
}
let Ve = (t) => {
  try {
    return t.only([[]]), Ve = () => [[]], [[]];
  } catch {
    return Ve = () => le, le;
  }
};
function $t(t) {
  return t == null ? () => {
  } : typeof t == "string" ? function(e) {
    return e.split(".").length === 1 ? (r) => r[e] : (r) => ee(r, e);
  }(t) : (e) => ee(e, t);
}
function wn(t) {
  return [].slice.call(t);
}
let jr = 0;
function Ne(t) {
  return t == null ? ":id" : typeof t == "string" ? t : `[${t.join("+")}]`;
}
function Tr(t, e, n) {
  function r(a) {
    if (a.type === 3)
      return null;
    if (a.type === 4)
      throw new Error("Cannot convert never type to IDBKeyRange");
    const { lower: d, upper: c, lowerOpen: l, upperOpen: h } = a;
    return d === void 0 ? c === void 0 ? null : e.upperBound(c, !!h) : c === void 0 ? e.lowerBound(d, !!l) : e.bound(d, c, !!l, !!h);
  }
  const { schema: s, hasGetAll: i } = function(a, d) {
    const c = wn(a.objectStoreNames);
    return { schema: { name: a.name, tables: c.map((l) => d.objectStore(l)).map((l) => {
      const { keyPath: h, autoIncrement: p } = l, g = M(h), f = h == null, v = {}, m = { name: l.name, primaryKey: { name: null, isPrimaryKey: !0, outbound: f, compound: g, keyPath: h, autoIncrement: p, unique: !0, extractKey: $t(h) }, indexes: wn(l.indexNames).map((y) => l.index(y)).map((y) => {
        const { name: w, unique: P, multiEntry: x, keyPath: k } = y, b = { name: w, compound: M(k), keyPath: k, unique: P, multiEntry: x, extractKey: $t(k) };
        return v[Ne(k)] = b, b;
      }), getIndexByKeyPath: (y) => v[Ne(y)] };
      return v[":id"] = m.primaryKey, h != null && (v[Ne(h)] = m.primaryKey), m;
    }) }, hasGetAll: c.length > 0 && "getAll" in d.objectStore(c[0]) && !(typeof navigator < "u" && /Safari/.test(navigator.userAgent) && !/(Chrome\/|Edge\/)/.test(navigator.userAgent) && [].concat(navigator.userAgent.match(/Safari\/(\d*)/))[1] < 604) };
  }(t, n), o = s.tables.map((a) => function(d) {
    const c = d.name;
    return { name: c, schema: d, mutate: function({ trans: l, type: h, keys: p, values: g, range: f }) {
      return new Promise((v, m) => {
        v = j(v);
        const y = l.objectStore(c), w = y.keyPath == null, P = h === "put" || h === "add";
        if (!P && h !== "delete" && h !== "deleteRange")
          throw new Error("Invalid operation type: " + h);
        const { length: x } = p || g || { length: 1 };
        if (p && g && p.length !== g.length)
          throw new Error("Given keys array must have same length as given values array.");
        if (x === 0)
          return v({ numFailures: 0, failures: {}, results: [], lastResult: void 0 });
        let k;
        const b = [], K = [];
        let _ = 0;
        const T = (q) => {
          ++_, $e(q);
        };
        if (h === "deleteRange") {
          if (f.type === 4)
            return v({ numFailures: _, failures: K, results: [], lastResult: void 0 });
          f.type === 3 ? b.push(k = y.clear()) : b.push(k = y.delete(r(f)));
        } else {
          const [q, N] = P ? w ? [g, p] : [g, null] : [p, null];
          if (P)
            for (let D = 0; D < x; ++D)
              b.push(k = N && N[D] !== void 0 ? y[h](q[D], N[D]) : y[h](q[D])), k.onerror = T;
          else
            for (let D = 0; D < x; ++D)
              b.push(k = y[h](q[D])), k.onerror = T;
        }
        const O = (q) => {
          const N = q.target.result;
          b.forEach((D, ge) => D.error != null && (K[ge] = D.error)), v({ numFailures: _, failures: K, results: h === "delete" ? p : b.map((D) => D.result), lastResult: N });
        };
        k.onerror = (q) => {
          T(q), O(q);
        }, k.onsuccess = O;
      });
    }, getMany: ({ trans: l, keys: h }) => new Promise((p, g) => {
      p = j(p);
      const f = l.objectStore(c), v = h.length, m = new Array(v);
      let y, w = 0, P = 0;
      const x = (b) => {
        const K = b.target;
        m[K._pos] = K.result, ++P === w && p(m);
      }, k = H(g);
      for (let b = 0; b < v; ++b)
        h[b] != null && (y = f.get(h[b]), y._pos = b, y.onsuccess = x, y.onerror = k, ++w);
      w === 0 && p(m);
    }), get: ({ trans: l, key: h }) => new Promise((p, g) => {
      p = j(p);
      const f = l.objectStore(c).get(h);
      f.onsuccess = (v) => p(v.target.result), f.onerror = H(g);
    }), query: function(l) {
      return (h) => new Promise((p, g) => {
        p = j(p);
        const { trans: f, values: v, limit: m, query: y } = h, w = m === 1 / 0 ? void 0 : m, { index: P, range: x } = y, k = f.objectStore(c), b = P.isPrimaryKey ? k : k.index(P.name), K = r(x);
        if (m === 0)
          return p({ result: [] });
        if (l) {
          const _ = v ? b.getAll(K, w) : b.getAllKeys(K, w);
          _.onsuccess = (T) => p({ result: T.target.result }), _.onerror = H(g);
        } else {
          let _ = 0;
          const T = v || !("openKeyCursor" in b) ? b.openCursor(K) : b.openKeyCursor(K), O = [];
          T.onsuccess = (q) => {
            const N = T.result;
            return N ? (O.push(v ? N.value : N.primaryKey), ++_ === m ? p({ result: O }) : void N.continue()) : p({ result: O });
          }, T.onerror = H(g);
        }
      });
    }(i), openCursor: function({ trans: l, values: h, query: p, reverse: g, unique: f }) {
      return new Promise((v, m) => {
        v = j(v);
        const { index: y, range: w } = p, P = l.objectStore(c), x = y.isPrimaryKey ? P : P.index(y.name), k = g ? f ? "prevunique" : "prev" : f ? "nextunique" : "next", b = h || !("openKeyCursor" in x) ? x.openCursor(r(w), k) : x.openKeyCursor(r(w), k);
        b.onerror = H(m), b.onsuccess = j((K) => {
          const _ = b.result;
          if (!_)
            return void v(null);
          _.___id = ++jr, _.done = !1;
          const T = _.continue.bind(_);
          let O = _.continuePrimaryKey;
          O && (O = O.bind(_));
          const q = _.advance.bind(_), N = () => {
            throw new Error("Cursor not stopped");
          };
          _.trans = l, _.stop = _.continue = _.continuePrimaryKey = _.advance = () => {
            throw new Error("Cursor not started");
          }, _.fail = j(m), _.next = function() {
            let D = 1;
            return this.start(() => D-- ? this.continue() : this.stop()).then(() => this);
          }, _.start = (D) => {
            const ge = new Promise((V, ue) => {
              V = j(V), b.onerror = H(ue), _.fail = ue, _.stop = (Ke) => {
                _.stop = _.continue = _.continuePrimaryKey = _.advance = N, V(Ke);
              };
            }), ve = () => {
              if (b.result)
                try {
                  D();
                } catch (V) {
                  _.fail(V);
                }
              else
                _.done = !0, _.start = () => {
                  throw new Error("Cursor behind last entry");
                }, _.stop();
            };
            return b.onsuccess = j((V) => {
              b.onsuccess = ve, ve();
            }), _.continue = T, _.continuePrimaryKey = O, _.advance = q, ve(), ge;
          }, v(_);
        }, m);
      });
    }, count({ query: l, trans: h }) {
      const { index: p, range: g } = l;
      return new Promise((f, v) => {
        const m = h.objectStore(c), y = p.isPrimaryKey ? m : m.index(p.name), w = r(g), P = w ? y.count(w) : y.count();
        P.onsuccess = j((x) => f(x.target.result)), P.onerror = H(v);
      });
    } };
  }(a)), u = {};
  return o.forEach((a) => u[a.name] = a), { stack: "dbcore", transaction: t.transaction.bind(t), table(a) {
    if (!u[a])
      throw new Error(`Table '${a}' not found`);
    return u[a];
  }, MIN_KEY: -1 / 0, MAX_KEY: Ve(e), schema: s };
}
function Ut({ _novip: t }, e) {
  const n = e.db, r = function(s, i, { IDBKeyRange: o, indexedDB: u }, a) {
    return { dbcore: function(c, l) {
      return l.reduce((h, { create: p }) => ({ ...h, ...p(h) }), c);
    }(Tr(i, o, a), s.dbcore) };
  }(t._middlewares, n, t._deps, e);
  t.core = r.dbcore, t.tables.forEach((s) => {
    const i = s.name;
    t.core.schema.tables.some((o) => o.name === i) && (s.core = t.core.table(i), t[i] instanceof t.Table && (t[i].core = s.core));
  });
}
function pt({ _novip: t }, e, n, r) {
  n.forEach((s) => {
    const i = r[s];
    e.forEach((o) => {
      const u = Jt(o, s);
      (!u || "value" in u && u.value === void 0) && (o === t.Transaction.prototype || o instanceof t.Transaction ? Z(o, s, { get() {
        return this.table(s);
      }, set(a) {
        xn(this, s, { value: a, writable: !0, configurable: !0, enumerable: !0 });
      } }) : o[s] = new t.Table(s, i));
    });
  });
}
function Vt({ _novip: t }, e) {
  e.forEach((n) => {
    for (let r in n)
      n[r] instanceof t.Table && delete n[r];
  });
}
function Dr(t, e) {
  return t._cfg.version - e._cfg.version;
}
function Br(t, e, n, r) {
  const s = t._dbSchema, i = t._createTransaction("readwrite", t._storeNames, s);
  i.create(n), i._completion.catch(r);
  const o = i._reject.bind(i), u = A.transless || A;
  ie(() => {
    A.trans = i, A.transless = u, e === 0 ? (B(s).forEach((a) => {
      Pt(n, a, s[a].primKey, s[a].indexes);
    }), Ut(t, n), E.follow(() => t.on.populate.fire(i)).catch(o)) : function({ _novip: a }, d, c, l) {
      const h = [], p = a._versions;
      let g = a._dbSchema = Wt(a, a.idbdb, l), f = !1;
      const v = p.filter((y) => y._cfg.version >= d);
      function m() {
        return h.length ? E.resolve(h.shift()(c.idbtrans)).then(m) : E.resolve();
      }
      return v.forEach((y) => {
        h.push(() => {
          const w = g, P = y._cfg.dbschema;
          zt(a, w, l), zt(a, P, l), g = a._dbSchema = P;
          const x = Qn(w, P);
          x.add.forEach((b) => {
            Pt(l, b[0], b[1].primKey, b[1].indexes);
          }), x.change.forEach((b) => {
            if (b.recreate)
              throw new S.Upgrade("Not yet support for changing primary key");
            {
              const K = l.objectStore(b.name);
              b.add.forEach((_) => Lt(K, _)), b.change.forEach((_) => {
                K.deleteIndex(_.name), Lt(K, _);
              }), b.del.forEach((_) => K.deleteIndex(_));
            }
          });
          const k = y._cfg.contentUpgrade;
          if (k && y._cfg.version > d) {
            Ut(a, l), c._memoizedTables = {}, f = !0;
            let b = Sn(P);
            x.del.forEach((O) => {
              b[O] = w[O];
            }), Vt(a, [a.Transaction.prototype]), pt(a, [a.Transaction.prototype], B(b), b), c.schema = b;
            const K = Xt(k);
            let _;
            K && Ae();
            const T = E.follow(() => {
              if (_ = k(c), _ && K) {
                var O = te.bind(null, null);
                _.then(O, O);
              }
            });
            return _ && typeof _.then == "function" ? E.resolve(_) : T.then(() => _);
          }
        }), h.push((w) => {
          (!f || !Er) && function(P, x) {
            [].slice.call(x.db.objectStoreNames).forEach((k) => P[k] == null && x.db.deleteObjectStore(k));
          }(y._cfg.dbschema, w), Vt(a, [a.Transaction.prototype]), pt(a, [a.Transaction.prototype], a._storeNames, a._dbSchema), c.schema = a._dbSchema;
        });
      }), m().then(() => {
        var y, w;
        w = l, B(y = g).forEach((P) => {
          w.db.objectStoreNames.contains(P) || Pt(w, P, y[P].primKey, y[P].indexes);
        });
      });
    }(t, e, i, n).catch(o);
  });
}
function Qn(t, e) {
  const n = { del: [], add: [], change: [] };
  let r;
  for (r in t)
    e[r] || n.del.push(r);
  for (r in e) {
    const s = t[r], i = e[r];
    if (s) {
      const o = { name: r, def: i, recreate: !1, del: [], add: [], change: [] };
      if ("" + (s.primKey.keyPath || "") != "" + (i.primKey.keyPath || "") || s.primKey.auto !== i.primKey.auto && !mt)
        o.recreate = !0, n.change.push(o);
      else {
        const u = s.idxByName, a = i.idxByName;
        let d;
        for (d in u)
          a[d] || o.del.push(d);
        for (d in a) {
          const c = u[d], l = a[d];
          c ? c.src !== l.src && o.change.push(l) : o.add.push(l);
        }
        (o.del.length > 0 || o.add.length > 0 || o.change.length > 0) && n.change.push(o);
      }
    } else
      n.add.push([r, i]);
  }
  return n;
}
function Pt(t, e, n, r) {
  const s = t.db.createObjectStore(e, n.keyPath ? { keyPath: n.keyPath, autoIncrement: n.auto } : { autoIncrement: n.auto });
  return r.forEach((i) => Lt(s, i)), s;
}
function Lt(t, e) {
  t.createIndex(e.name, e.keyPath, { unique: e.unique, multiEntry: e.multi });
}
function Wt(t, e, n) {
  const r = {};
  return ut(e.objectStoreNames, 0).forEach((s) => {
    const i = n.objectStore(s);
    let o = i.keyPath;
    const u = qt(Yn(o), o || "", !1, !1, !!i.autoIncrement, o && typeof o != "string", !0), a = [];
    for (let c = 0; c < i.indexNames.length; ++c) {
      const l = i.index(i.indexNames[c]);
      o = l.keyPath;
      var d = qt(l.name, o, !!l.unique, !!l.multiEntry, !1, o && typeof o != "string", !1);
      a.push(d);
    }
    r[s] = Gn(s, u, a);
  }), r;
}
function zt({ _novip: t }, e, n) {
  const r = n.db.objectStoreNames;
  for (let s = 0; s < r.length; ++s) {
    const i = r[s], o = n.objectStore(i);
    t._hasGetAll = "getAll" in o;
    for (let u = 0; u < o.indexNames.length; ++u) {
      const a = o.indexNames[u], d = o.index(a).keyPath, c = typeof d == "string" ? d : "[" + ut(d).join("+") + "]";
      if (e[i]) {
        const l = e[i].idxByName[c];
        l && (l.name = a, delete e[i].idxByName[c], e[i].idxByName[a] = l);
      }
    }
  }
  typeof navigator < "u" && /Safari/.test(navigator.userAgent) && !/(Chrome\/|Edge\/)/.test(navigator.userAgent) && I.WorkerGlobalScope && I instanceof I.WorkerGlobalScope && [].concat(navigator.userAgent.match(/Safari\/(\d*)/))[1] < 604 && (t._hasGetAll = !1);
}
class Rr {
  _parseStoresSpec(e, n) {
    B(e).forEach((r) => {
      if (e[r] !== null) {
        var s = e[r].split(",").map((o, u) => {
          const a = (o = o.trim()).replace(/([&*]|\+\+)/g, ""), d = /^\[/.test(a) ? a.match(/^\[(.*)\]$/)[1].split("+") : a;
          return qt(a, d || null, /\&/.test(o), /\*/.test(o), /\+\+/.test(o), M(d), u === 0);
        }), i = s.shift();
        if (i.multi)
          throw new S.Schema("Primary key cannot be multi-valued");
        s.forEach((o) => {
          if (o.auto)
            throw new S.Schema("Only primary key can be marked as autoIncrement (++)");
          if (!o.keyPath)
            throw new S.Schema("Index must have a name and cannot be an empty string");
        }), n[r] = Gn(r, i, s);
      }
    });
  }
  stores(e) {
    const n = this.db;
    this._cfg.storesSource = this._cfg.storesSource ? U(this._cfg.storesSource, e) : e;
    const r = n._versions, s = {};
    let i = {};
    return r.forEach((o) => {
      U(s, o._cfg.storesSource), i = o._cfg.dbschema = {}, o._parseStoresSpec(s, i);
    }), n._dbSchema = i, Vt(n, [n._allTables, n, n.Transaction.prototype]), pt(n, [n._allTables, n, n.Transaction.prototype, this._cfg.tables], B(i), i), n._storeNames = B(i), this;
  }
  upgrade(e) {
    return this._cfg.contentUpgrade = tn(this._cfg.contentUpgrade || C, e), this;
  }
}
function on(t, e) {
  let n = t._dbNamesDB;
  return n || (n = t._dbNamesDB = new fe(gt, { addons: [], indexedDB: t, IDBKeyRange: e }), n.version(1).stores({ dbnames: "name" })), n.table("dbnames");
}
function an(t) {
  return t && typeof t.databases == "function";
}
function Ht(t) {
  return ie(function() {
    return A.letThrough = !0, t();
  });
}
function Nr() {
  var t;
  return !navigator.userAgentData && /Safari\//.test(navigator.userAgent) && !/Chrom(e|ium)\//.test(navigator.userAgent) && indexedDB.databases ? new Promise(function(e) {
    var n = function() {
      return indexedDB.databases().finally(e);
    };
    t = setInterval(n, 100), n();
  }).finally(function() {
    return clearInterval(t);
  }) : Promise.resolve();
}
function Fr(t) {
  const e = t._state, { indexedDB: n } = t._deps;
  if (e.isBeingOpened || t.idbdb)
    return e.dbReadyPromise.then(() => e.dbOpenError ? R(e.dbOpenError) : t);
  Y && (e.openCanceller._stackHolder = me()), e.isBeingOpened = !0, e.dbOpenError = null, e.openComplete = !1;
  const r = e.openCanceller;
  function s() {
    if (e.openCanceller !== r)
      throw new S.DatabaseClosed("db.open() was cancelled");
  }
  let i = e.dbReadyResolve, o = null, u = !1;
  return E.race([r, (typeof navigator > "u" ? E.resolve() : Nr()).then(() => new E((a, d) => {
    if (s(), !n)
      throw new S.MissingAPI();
    const c = t.name, l = e.autoSchema ? n.open(c) : n.open(c, Math.round(10 * t.verno));
    if (!l)
      throw new S.MissingAPI();
    l.onerror = H(d), l.onblocked = j(t._fireOnBlocked), l.onupgradeneeded = j((h) => {
      if (o = l.transaction, e.autoSchema && !t._options.allowEmptyDB) {
        l.onerror = $e, o.abort(), l.result.close();
        const g = n.deleteDatabase(c);
        g.onsuccess = g.onerror = j(() => {
          d(new S.NoSuchDatabase(`Database ${c} doesnt exist`));
        });
      } else {
        o.onerror = H(d);
        var p = h.oldVersion > Math.pow(2, 62) ? 0 : h.oldVersion;
        u = p < 1, t._novip.idbdb = l.result, Br(t, p / 10, o, d);
      }
    }, d), l.onsuccess = j(() => {
      o = null;
      const h = t._novip.idbdb = l.result, p = ut(h.objectStoreNames);
      if (p.length > 0)
        try {
          const f = h.transaction((g = p).length === 1 ? g[0] : g, "readonly");
          e.autoSchema ? function({ _novip: v }, m, y) {
            v.verno = m.version / 10;
            const w = v._dbSchema = Wt(0, m, y);
            v._storeNames = ut(m.objectStoreNames, 0), pt(v, [v._allTables], B(w), w);
          }(t, h, f) : (zt(t, t._dbSchema, f), function(v, m) {
            const y = Qn(Wt(0, v.idbdb, m), v._dbSchema);
            return !(y.add.length || y.change.some((w) => w.add.length || w.change.length));
          }(t, f) || console.warn("Dexie SchemaDiff: Schema was extended without increasing the number passed to db.version(). Some queries may fail.")), Ut(t, f);
        } catch {
        }
      var g;
      Be.push(t), h.onversionchange = j((f) => {
        e.vcFired = !0, t.on("versionchange").fire(f);
      }), h.onclose = j((f) => {
        t.on("close").fire(f);
      }), u && function({ indexedDB: f, IDBKeyRange: v }, m) {
        !an(f) && m !== gt && on(f, v).put({ name: m }).catch(C);
      }(t._deps, c), a();
    }, d);
  }))]).then(() => (s(), e.onReadyBeingFired = [], E.resolve(Ht(() => t.on.ready.fire(t.vip))).then(function a() {
    if (e.onReadyBeingFired.length > 0) {
      let d = e.onReadyBeingFired.reduce(tn, C);
      return e.onReadyBeingFired = [], E.resolve(Ht(() => d(t.vip))).then(a);
    }
  }))).finally(() => {
    e.onReadyBeingFired = null, e.isBeingOpened = !1;
  }).then(() => t).catch((a) => {
    e.dbOpenError = a;
    try {
      o && o.abort();
    } catch {
    }
    return r === e.openCanceller && t._close(), R(a);
  }).finally(() => {
    e.openComplete = !0, i();
  });
}
function Yt(t) {
  var e = (i) => t.next(i), n = s(e), r = s((i) => t.throw(i));
  function s(i) {
    return (o) => {
      var u = i(o), a = u.value;
      return u.done ? a : a && typeof a.then == "function" ? a.then(n, r) : M(a) ? Promise.all(a).then(n, r) : n(a);
    };
  }
  return s(e)();
}
function Mr(t, e, n) {
  var r = arguments.length;
  if (r < 2)
    throw new S.InvalidArgument("Too few arguments");
  for (var s = new Array(r - 1); --r; )
    s[r - 1] = arguments[r];
  return n = s.pop(), [t, Kn(s), n];
}
function Jn(t, e, n, r, s) {
  return E.resolve().then(() => {
    const i = A.transless || A, o = t._createTransaction(e, n, t._dbSchema, r), u = { trans: o, transless: i };
    if (r)
      o.idbtrans = r.idbtrans;
    else
      try {
        o.create(), t._state.PR1398_maxLoop = 3;
      } catch (l) {
        return l.name === en.InvalidState && t.isOpen() && --t._state.PR1398_maxLoop > 0 ? (console.warn("Dexie: Need to reopen db"), t._close(), t.open().then(() => Jn(t, e, n, null, s))) : R(l);
      }
    const a = Xt(s);
    let d;
    a && Ae();
    const c = E.follow(() => {
      if (d = s.call(o, o), d)
        if (a) {
          var l = te.bind(null, null);
          d.then(l, l);
        } else
          typeof d.next == "function" && typeof d.throw == "function" && (d = Yt(d));
    }, u);
    return (d && typeof d.then == "function" ? E.resolve(d).then((l) => o.active ? l : R(new S.PrematureCommit("Transaction committed too early. See http://bit.ly/2kdckMn"))) : c.then(() => d)).then((l) => (r && o._resolve(), o._completion.then(() => l))).catch((l) => (o._reject(l), R(l)));
  });
}
function et(t, e, n) {
  const r = M(t) ? t.slice() : [t];
  for (let s = 0; s < n; ++s)
    r.push(e);
  return r;
}
const qr = { stack: "dbcore", name: "VirtualIndexMiddleware", level: 1, create: function(t) {
  return { ...t, table(e) {
    const n = t.table(e), { schema: r } = n, s = {}, i = [];
    function o(c, l, h) {
      const p = Ne(c), g = s[p] = s[p] || [], f = c == null ? 0 : typeof c == "string" ? 1 : c.length, v = l > 0, m = { ...h, isVirtual: v, keyTail: l, keyLength: f, extractKey: $t(c), unique: !v && h.unique };
      return g.push(m), m.isPrimaryKey || i.push(m), f > 1 && o(f === 2 ? c[0] : c.slice(0, f - 1), l + 1, h), g.sort((y, w) => y.keyTail - w.keyTail), m;
    }
    const u = o(r.primaryKey.keyPath, 0, r.primaryKey);
    s[":id"] = [u];
    for (const c of r.indexes)
      o(c.keyPath, 0, c);
    function a(c) {
      const l = c.query.index;
      return l.isVirtual ? { ...c, query: { index: l, range: (h = c.query.range, p = l.keyTail, { type: h.type === 1 ? 2 : h.type, lower: et(h.lower, h.lowerOpen ? t.MAX_KEY : t.MIN_KEY, p), lowerOpen: !0, upper: et(h.upper, h.upperOpen ? t.MIN_KEY : t.MAX_KEY, p), upperOpen: !0 }) } } : c;
      var h, p;
    }
    return { ...n, schema: { ...r, primaryKey: u, indexes: i, getIndexByKeyPath: function(c) {
      const l = s[Ne(c)];
      return l && l[0];
    } }, count: (c) => n.count(a(c)), query: (c) => n.query(a(c)), openCursor(c) {
      const { keyTail: l, isVirtual: h, keyLength: p } = c.query.index;
      return h ? n.openCursor(a(c)).then((g) => g && function(f) {
        return Object.create(f, { continue: { value: function(m) {
          m != null ? f.continue(et(m, c.reverse ? t.MAX_KEY : t.MIN_KEY, l)) : c.unique ? f.continue(f.key.slice(0, p).concat(c.reverse ? t.MIN_KEY : t.MAX_KEY, l)) : f.continue();
        } }, continuePrimaryKey: { value(m, y) {
          f.continuePrimaryKey(et(m, t.MAX_KEY, l), y);
        } }, primaryKey: { get: () => f.primaryKey }, key: { get() {
          const m = f.key;
          return p === 1 ? m[0] : m.slice(0, p);
        } }, value: { get: () => f.value } });
      }(g)) : n.openCursor(c);
    } };
  } };
} };
function un(t, e, n, r) {
  return n = n || {}, r = r || "", B(t).forEach((s) => {
    if (W(e, s)) {
      var i = t[s], o = e[s];
      if (typeof i == "object" && typeof o == "object" && i && o) {
        const u = St(i);
        u !== St(o) ? n[r + s] = e[s] : u === "Object" ? un(i, o, n, r + s + ".") : i !== o && (n[r + s] = e[s]);
      } else
        i !== o && (n[r + s] = e[s]);
    } else
      n[r + s] = void 0;
  }), B(e).forEach((s) => {
    W(t, s) || (n[r + s] = e[s]);
  }), n;
}
const $r = { stack: "dbcore", name: "HooksMiddleware", level: 2, create: (t) => ({ ...t, table(e) {
  const n = t.table(e), { primaryKey: r } = n.schema;
  return { ...n, mutate(i) {
    const o = A.trans, { deleting: u, creating: a, updating: d } = o.table(e).hook;
    switch (i.type) {
      case "add":
        if (a.fire === C)
          break;
        return o._promise("readwrite", () => c(i), !0);
      case "put":
        if (a.fire === C && d.fire === C)
          break;
        return o._promise("readwrite", () => c(i), !0);
      case "delete":
        if (u.fire === C)
          break;
        return o._promise("readwrite", () => c(i), !0);
      case "deleteRange":
        if (u.fire === C)
          break;
        return o._promise("readwrite", () => function(h) {
          return l(h.trans, h.range, 1e4);
        }(i), !0);
    }
    return n.mutate(i);
    function c(h) {
      const p = A.trans, g = h.keys || function(f, v) {
        return v.type === "delete" ? v.keys : v.keys || v.values.map(f.extractKey);
      }(r, h);
      if (!g)
        throw new Error("Keys missing");
      return (h = h.type === "add" || h.type === "put" ? { ...h, keys: g } : { ...h }).type !== "delete" && (h.values = [...h.values]), h.keys && (h.keys = [...h.keys]), function(f, v, m) {
        return v.type === "add" ? Promise.resolve([]) : f.getMany({ trans: v.trans, keys: m, cache: "immutable" });
      }(n, h, g).then((f) => {
        const v = g.map((m, y) => {
          const w = f[y], P = { onerror: null, onsuccess: null };
          if (h.type === "delete")
            u.fire.call(P, m, w, p);
          else if (h.type === "add" || w === void 0) {
            const x = a.fire.call(P, m, h.values[y], p);
            m == null && x != null && (m = x, h.keys[y] = m, r.outbound || z(h.values[y], r.keyPath, m));
          } else {
            const x = un(w, h.values[y]), k = d.fire.call(P, x, m, w, p);
            if (k) {
              const b = h.values[y];
              Object.keys(k).forEach((K) => {
                W(b, K) ? b[K] = k[K] : z(b, K, k[K]);
              });
            }
          }
          return P;
        });
        return n.mutate(h).then(({ failures: m, results: y, numFailures: w, lastResult: P }) => {
          for (let x = 0; x < g.length; ++x) {
            const k = y ? y[x] : g[x], b = v[x];
            k == null ? b.onerror && b.onerror(m[x]) : b.onsuccess && b.onsuccess(h.type === "put" && f[x] ? h.values[x] : k);
          }
          return { failures: m, results: y, numFailures: w, lastResult: P };
        }).catch((m) => (v.forEach((y) => y.onerror && y.onerror(m)), Promise.reject(m)));
      });
    }
    function l(h, p, g) {
      return n.query({ trans: h, values: !1, query: { index: r, range: p }, limit: g }).then(({ result: f }) => c({ type: "delete", keys: f, trans: h }).then((v) => v.numFailures > 0 ? Promise.reject(v.failures[0]) : f.length < g ? { failures: [], numFailures: 0, lastResult: void 0 } : l(h, { ...p, lower: f[f.length - 1], lowerOpen: !0 }, g)));
    }
  } };
} }) };
function Xn(t, e, n) {
  try {
    if (!e || e.keys.length < t.length)
      return null;
    const r = [];
    for (let s = 0, i = 0; s < e.keys.length && i < t.length; ++s)
      $(e.keys[s], t[i]) === 0 && (r.push(n ? We(e.values[s]) : e.values[s]), ++i);
    return r.length === t.length ? r : null;
  } catch {
    return null;
  }
}
const Ur = { stack: "dbcore", level: -1, create: (t) => ({ table: (e) => {
  const n = t.table(e);
  return { ...n, getMany: (r) => {
    if (!r.cache)
      return n.getMany(r);
    const s = Xn(r.keys, r.trans._cache, r.cache === "clone");
    return s ? E.resolve(s) : n.getMany(r).then((i) => (r.trans._cache = { keys: r.keys, values: r.cache === "clone" ? We(i) : i }, i));
  }, mutate: (r) => (r.type !== "add" && (r.trans._cache = null), n.mutate(r)) };
} }) };
function cn(t) {
  return !("from" in t);
}
const Q = function(t, e) {
  if (!this) {
    const n = new Q();
    return t && "d" in t && U(n, t), n;
  }
  U(this, arguments.length ? { d: 1, from: t, to: arguments.length > 1 ? e : t } : { d: 0 });
};
function Le(t, e, n) {
  const r = $(e, n);
  if (isNaN(r))
    return;
  if (r > 0)
    throw RangeError();
  if (cn(t))
    return U(t, { from: e, to: n, d: 1 });
  const s = t.l, i = t.r;
  if ($(n, t.from) < 0)
    return s ? Le(s, e, n) : t.l = { from: e, to: n, d: 1, l: null, r: null }, _n(t);
  if ($(e, t.to) > 0)
    return i ? Le(i, e, n) : t.r = { from: e, to: n, d: 1, l: null, r: null }, _n(t);
  $(e, t.from) < 0 && (t.from = e, t.l = null, t.d = i ? i.d + 1 : 1), $(n, t.to) > 0 && (t.to = n, t.r = null, t.d = t.l ? t.l.d + 1 : 1);
  const o = !t.r;
  s && !t.l && yt(t, s), i && o && yt(t, i);
}
function yt(t, e) {
  cn(e) || function n(r, { from: s, to: i, l: o, r: u }) {
    Le(r, s, i), o && n(r, o), u && n(r, u);
  }(t, e);
}
function Vr(t, e) {
  const n = Gt(e);
  let r = n.next();
  if (r.done)
    return !1;
  let s = r.value;
  const i = Gt(t);
  let o = i.next(s.from), u = o.value;
  for (; !r.done && !o.done; ) {
    if ($(u.from, s.to) <= 0 && $(u.to, s.from) >= 0)
      return !0;
    $(s.from, u.from) < 0 ? s = (r = n.next(u.from)).value : u = (o = i.next(s.from)).value;
  }
  return !1;
}
function Gt(t) {
  let e = cn(t) ? null : { s: 0, n: t };
  return { next(n) {
    const r = arguments.length > 0;
    for (; e; )
      switch (e.s) {
        case 0:
          if (e.s = 1, r)
            for (; e.n.l && $(n, e.n.from) < 0; )
              e = { up: e, n: e.n.l, s: 1 };
          else
            for (; e.n.l; )
              e = { up: e, n: e.n.l, s: 1 };
        case 1:
          if (e.s = 2, !r || $(n, e.n.to) <= 0)
            return { value: e.n, done: !1 };
        case 2:
          if (e.n.r) {
            e.s = 3, e = { up: e, n: e.n.r, s: 0 };
            continue;
          }
        case 3:
          e = e.up;
      }
    return { done: !0 };
  } };
}
function _n(t) {
  var e, n;
  const r = (((e = t.r) === null || e === void 0 ? void 0 : e.d) || 0) - (((n = t.l) === null || n === void 0 ? void 0 : n.d) || 0), s = r > 1 ? "r" : r < -1 ? "l" : "";
  if (s) {
    const i = s === "r" ? "l" : "r", o = { ...t }, u = t[s];
    t.from = u.from, t.to = u.to, t[s] = u[s], o[s] = u[i], t[i] = o, o.d = kn(o);
  }
  t.d = kn(t);
}
function kn({ r: t, l: e }) {
  return (t ? e ? Math.max(t.d, e.d) : t.d : e ? e.d : 0) + 1;
}
Pe(Q.prototype, { add(t) {
  return yt(this, t), this;
}, addKey(t) {
  return Le(this, t, t), this;
}, addKeys(t) {
  return t.forEach((e) => Le(this, e, e)), this;
}, [Kt]() {
  return Gt(this);
} });
const Lr = { stack: "dbcore", level: 0, create: (t) => {
  const e = t.schema.name, n = new Q(t.MIN_KEY, t.MAX_KEY);
  return { ...t, table: (r) => {
    const s = t.table(r), { schema: i } = s, { primaryKey: o } = i, { extractKey: u, outbound: a } = o, d = { ...s, mutate: (h) => {
      const p = h.trans, g = p.mutatedParts || (p.mutatedParts = {}), f = (k) => {
        const b = `idb://${e}/${r}/${k}`;
        return g[b] || (g[b] = new Q());
      }, v = f(""), m = f(":dels"), { type: y } = h;
      let [w, P] = h.type === "deleteRange" ? [h.range] : h.type === "delete" ? [h.keys] : h.values.length < 50 ? [[], h.values] : [];
      const x = h.trans._cache;
      return s.mutate(h).then((k) => {
        if (M(w)) {
          y !== "delete" && (w = k.results), v.addKeys(w);
          const b = Xn(w, x);
          b || y === "add" || m.addKeys(w), (b || P) && function(K, _, T, O) {
            function q(N) {
              const D = K(N.name || "");
              function ge(V) {
                return V != null ? N.extractKey(V) : null;
              }
              const ve = (V) => N.multiEntry && M(V) ? V.forEach((ue) => D.addKey(ue)) : D.addKey(V);
              (T || O).forEach((V, ue) => {
                const Ke = T && ge(T[ue]), vt = O && ge(O[ue]);
                $(Ke, vt) !== 0 && (Ke != null && ve(Ke), vt != null && ve(vt));
              });
            }
            _.indexes.forEach(q);
          }(f, i, b, P);
        } else if (w) {
          const b = { from: w.lower, to: w.upper };
          m.add(b), v.add(b);
        } else
          v.add(n), m.add(n), i.indexes.forEach((b) => f(b.name).add(n));
        return k;
      });
    } }, c = ({ query: { index: h, range: p } }) => {
      var g, f;
      return [h, new Q((g = p.lower) !== null && g !== void 0 ? g : t.MIN_KEY, (f = p.upper) !== null && f !== void 0 ? f : t.MAX_KEY)];
    }, l = { get: (h) => [o, new Q(h.key)], getMany: (h) => [o, new Q().addKeys(h.keys)], count: c, query: c, openCursor: c };
    return B(l).forEach((h) => {
      d[h] = function(p) {
        const { subscr: g } = A;
        if (g) {
          const f = (P) => {
            const x = `idb://${e}/${r}/${P}`;
            return g[x] || (g[x] = new Q());
          }, v = f(""), m = f(":dels"), [y, w] = l[h](p);
          if (f(y.name || "").add(w), !y.isPrimaryKey) {
            if (h !== "count") {
              const P = h === "query" && a && p.values && s.query({ ...p, values: !1 });
              return s[h].apply(this, arguments).then((x) => {
                if (h === "query") {
                  if (a && p.values)
                    return P.then(({ result: b }) => (v.addKeys(b), x));
                  const k = p.values ? x.result.map(u) : x.result;
                  p.values ? v.addKeys(k) : m.addKeys(k);
                } else if (h === "openCursor") {
                  const k = x, b = p.values;
                  return k && Object.create(k, { key: { get: () => (m.addKey(k.primaryKey), k.key) }, primaryKey: { get() {
                    const K = k.primaryKey;
                    return m.addKey(K), K;
                  } }, value: { get: () => (b && v.addKey(k.primaryKey), k.value) } });
                }
                return x;
              });
            }
            m.add(n);
          }
        }
        return s[h].apply(this, arguments);
      };
    }), d;
  } };
} };
class fe {
  constructor(e, n) {
    this._middlewares = {}, this.verno = 0;
    const r = fe.dependencies;
    this._options = n = { addons: fe.addons, autoOpen: !0, indexedDB: r.indexedDB, IDBKeyRange: r.IDBKeyRange, ...n }, this._deps = { indexedDB: n.indexedDB, IDBKeyRange: n.IDBKeyRange };
    const { addons: s } = n;
    this._dbSchema = {}, this._versions = [], this._storeNames = [], this._allTables = {}, this.idbdb = null, this._novip = this;
    const i = { dbOpenError: null, isBeingOpened: !1, onReadyBeingFired: null, openComplete: !1, dbReadyResolve: C, dbReadyPromise: null, cancelOpen: C, openCanceller: null, autoSchema: !0, PR1398_maxLoop: 3 };
    var o;
    i.dbReadyPromise = new E((u) => {
      i.dbReadyResolve = u;
    }), i.openCanceller = new E((u, a) => {
      i.cancelOpen = a;
    }), this._state = i, this.name = e, this.on = Re(this, "populate", "blocked", "versionchange", "close", { ready: [tn, C] }), this.on.ready.subscribe = En(this.on.ready.subscribe, (u) => (a, d) => {
      fe.vip(() => {
        const c = this._state;
        if (c.openComplete)
          c.dbOpenError || E.resolve().then(a), d && u(a);
        else if (c.onReadyBeingFired)
          c.onReadyBeingFired.push(a), d && u(a);
        else {
          u(a);
          const l = this;
          d || u(function h() {
            l.on.ready.unsubscribe(a), l.on.ready.unsubscribe(h);
          });
        }
      });
    }), this.Collection = (o = this, Ce(Sr.prototype, function(u, a) {
      this.db = o;
      let d = Wn, c = null;
      if (a)
        try {
          d = a();
        } catch (g) {
          c = g;
        }
      const l = u._ctx, h = l.table, p = h.hook.reading.fire;
      this._ctx = { table: h, index: l.index, isPrimKey: !l.index || h.schema.primKey.keyPath && l.index === h.schema.primKey.name, range: d, keysOnly: !1, dir: "next", unique: "", algorithm: null, filter: null, replayFilter: null, justLimit: !0, isMatch: null, offset: 0, limit: 1 / 0, error: c, or: l.or, valueMapper: p !== Me ? p : null };
    })), this.Table = function(u) {
      return Ce(Ar.prototype, function(a, d, c) {
        this.db = u, this._tx = c, this.name = a, this.schema = d, this.hook = u._allTables[a] ? u._allTables[a].hook : Re(null, { creating: [pr, C], reading: [fr, Me], updating: [mr, C], deleting: [yr, C] });
      });
    }(this), this.Transaction = function(u) {
      return Ce(Ir.prototype, function(a, d, c, l, h) {
        this.db = u, this.mode = a, this.storeNames = d, this.schema = c, this.chromeTransactionDurability = l, this.idbtrans = null, this.on = Re(this, "complete", "error", "abort"), this.parent = h || null, this.active = !0, this._reculock = 0, this._blockedFuncs = [], this._resolve = null, this._reject = null, this._waitingFor = null, this._waitingQueue = null, this._spinCount = 0, this._completion = new E((p, g) => {
          this._resolve = p, this._reject = g;
        }), this._completion.then(() => {
          this.active = !1, this.on.complete.fire();
        }, (p) => {
          var g = this.active;
          return this.active = !1, this.on.error.fire(p), this.parent ? this.parent._reject(p) : g && this.idbtrans && this.idbtrans.abort(), R(p);
        });
      });
    }(this), this.Version = function(u) {
      return Ce(Rr.prototype, function(a) {
        this.db = u, this._cfg = { version: a, storesSource: null, dbschema: {}, tables: {}, contentUpgrade: null };
      });
    }(this), this.WhereClause = function(u) {
      return Ce(Hn.prototype, function(a, d, c) {
        this.db = u, this._ctx = { table: a, index: d === ":id" ? null : d, or: c };
        const l = u._deps.indexedDB;
        if (!l)
          throw new S.MissingAPI();
        this._cmp = this._ascending = l.cmp.bind(l), this._descending = (h, p) => l.cmp(p, h), this._max = (h, p) => l.cmp(h, p) > 0 ? h : p, this._min = (h, p) => l.cmp(h, p) < 0 ? h : p, this._IDBKeyRange = u._deps.IDBKeyRange;
      });
    }(this), this.on("versionchange", (u) => {
      u.newVersion > 0 ? console.warn(`Another connection wants to upgrade database '${this.name}'. Closing db now to resume the upgrade.`) : console.warn(`Another connection wants to delete database '${this.name}'. Closing db now to resume the delete request.`), this.close();
    }), this.on("blocked", (u) => {
      !u.newVersion || u.newVersion < u.oldVersion ? console.warn(`Dexie.delete('${this.name}') was blocked`) : console.warn(`Upgrade '${this.name}' blocked by other connection holding version ${u.oldVersion / 10}`);
    }), this._maxKey = Ve(n.IDBKeyRange), this._createTransaction = (u, a, d, c) => new this.Transaction(u, a, d, this._options.chromeTransactionDurability, c), this._fireOnBlocked = (u) => {
      this.on("blocked").fire(u), Be.filter((a) => a.name === this.name && a !== this && !a._state.vcFired).map((a) => a.on("versionchange").fire(u));
    }, this.use(qr), this.use($r), this.use(Lr), this.use(Ur), this.vip = Object.create(this, { _vip: { value: !0 } }), s.forEach((u) => u(this));
  }
  version(e) {
    if (isNaN(e) || e < 0.1)
      throw new S.Type("Given version is not a positive number");
    if (e = Math.round(10 * e) / 10, this.idbdb || this._state.isBeingOpened)
      throw new S.Schema("Cannot add version when database is open");
    this.verno = Math.max(this.verno, e);
    const n = this._versions;
    var r = n.filter((s) => s._cfg.version === e)[0];
    return r || (r = new this.Version(e), n.push(r), n.sort(Dr), r.stores({}), this._state.autoSchema = !1, r);
  }
  _whenReady(e) {
    return this.idbdb && (this._state.openComplete || A.letThrough || this._vip) ? e() : new E((n, r) => {
      if (this._state.openComplete)
        return r(new S.DatabaseClosed(this._state.dbOpenError));
      if (!this._state.isBeingOpened) {
        if (!this._options.autoOpen)
          return void r(new S.DatabaseClosed());
        this.open().catch(C);
      }
      this._state.dbReadyPromise.then(n, r);
    }).then(e);
  }
  use({ stack: e, create: n, level: r, name: s }) {
    s && this.unuse({ stack: e, name: s });
    const i = this._middlewares[e] || (this._middlewares[e] = []);
    return i.push({ stack: e, create: n, level: r ?? 10, name: s }), i.sort((o, u) => o.level - u.level), this;
  }
  unuse({ stack: e, name: n, create: r }) {
    return e && this._middlewares[e] && (this._middlewares[e] = this._middlewares[e].filter((s) => r ? s.create !== r : !!n && s.name !== n)), this;
  }
  open() {
    return Fr(this);
  }
  _close() {
    const e = this._state, n = Be.indexOf(this);
    if (n >= 0 && Be.splice(n, 1), this.idbdb) {
      try {
        this.idbdb.close();
      } catch {
      }
      this._novip.idbdb = null;
    }
    e.dbReadyPromise = new E((r) => {
      e.dbReadyResolve = r;
    }), e.openCanceller = new E((r, s) => {
      e.cancelOpen = s;
    });
  }
  close() {
    this._close();
    const e = this._state;
    this._options.autoOpen = !1, e.dbOpenError = new S.DatabaseClosed(), e.isBeingOpened && e.cancelOpen(e.dbOpenError);
  }
  delete() {
    const e = arguments.length > 0, n = this._state;
    return new E((r, s) => {
      const i = () => {
        this.close();
        var o = this._deps.indexedDB.deleteDatabase(this.name);
        o.onsuccess = j(() => {
          (function({ indexedDB: u, IDBKeyRange: a }, d) {
            !an(u) && d !== gt && on(u, a).delete(d).catch(C);
          })(this._deps, this.name), r();
        }), o.onerror = H(s), o.onblocked = this._fireOnBlocked;
      };
      if (e)
        throw new S.InvalidArgument("Arguments not allowed in db.delete()");
      n.isBeingOpened ? n.dbReadyPromise.then(i) : i();
    });
  }
  backendDB() {
    return this.idbdb;
  }
  isOpen() {
    return this.idbdb !== null;
  }
  hasBeenClosed() {
    const e = this._state.dbOpenError;
    return e && e.name === "DatabaseClosed";
  }
  hasFailed() {
    return this._state.dbOpenError !== null;
  }
  dynamicallyOpened() {
    return this._state.autoSchema;
  }
  get tables() {
    return B(this._allTables).map((e) => this._allTables[e]);
  }
  transaction() {
    const e = Mr.apply(this, arguments);
    return this._transaction.apply(this, e);
  }
  _transaction(e, n, r) {
    let s = A.trans;
    s && s.db === this && e.indexOf("!") === -1 || (s = null);
    const i = e.indexOf("?") !== -1;
    let o, u;
    e = e.replace("!", "").replace("?", "");
    try {
      if (u = n.map((d) => {
        var c = d instanceof this.Table ? d.name : d;
        if (typeof c != "string")
          throw new TypeError("Invalid table argument to Dexie.transaction(). Only Table or String are allowed");
        return c;
      }), e == "r" || e === wt)
        o = wt;
      else {
        if (e != "rw" && e != _t)
          throw new S.InvalidArgument("Invalid transaction mode: " + e);
        o = _t;
      }
      if (s) {
        if (s.mode === wt && o === _t) {
          if (!i)
            throw new S.SubTransaction("Cannot enter a sub-transaction with READWRITE mode when parent transaction is READONLY");
          s = null;
        }
        s && u.forEach((d) => {
          if (s && s.storeNames.indexOf(d) === -1) {
            if (!i)
              throw new S.SubTransaction("Table " + d + " not included in parent transaction.");
            s = null;
          }
        }), i && s && !s.active && (s = null);
      }
    } catch (d) {
      return s ? s._promise(null, (c, l) => {
        l(d);
      }) : R(d);
    }
    const a = Jn.bind(null, this, o, u, s, r);
    return s ? s._promise(o, a, "lock") : A.trans ? Se(A.transless, () => this._whenReady(a)) : this._whenReady(a);
  }
  table(e) {
    if (!W(this._allTables, e))
      throw new S.InvalidTable(`Table ${e} does not exist`);
    return this._allTables[e];
  }
}
const Wr = typeof Symbol < "u" && "observable" in Symbol ? Symbol.observable : "@@observable";
class zr {
  constructor(e) {
    this._subscribe = e;
  }
  subscribe(e, n, r) {
    return this._subscribe(e && typeof e != "function" ? e : { next: e, error: n, complete: r });
  }
  [Wr]() {
    return this;
  }
}
function Zn(t, e) {
  return B(e).forEach((n) => {
    yt(t[n] || (t[n] = new Q()), e[n]);
  }), t;
}
function Hr(t) {
  let e, n = !1;
  const r = new zr((s) => {
    const i = Xt(t);
    let o = !1, u = {}, a = {};
    const d = { get closed() {
      return o;
    }, unsubscribe: () => {
      o = !0, ae.storagemutated.unsubscribe(p);
    } };
    s.start && s.start(d);
    let c = !1, l = !1;
    function h() {
      return B(a).some((f) => u[f] && Vr(u[f], a[f]));
    }
    const p = (f) => {
      Zn(u, f), h() && g();
    }, g = () => {
      if (c || o)
        return;
      u = {};
      const f = {}, v = function(m) {
        i && Ae();
        const y = () => ie(t, { subscr: m, trans: null }), w = A.trans ? Se(A.transless, y) : y();
        return i && w.then(te, te), w;
      }(f);
      l || (ae(Ue, p), l = !0), c = !0, Promise.resolve(v).then((m) => {
        n = !0, e = m, c = !1, o || (h() ? g() : (u = {}, a = f, s.next && s.next(m)));
      }, (m) => {
        c = !1, n = !1, s.error && s.error(m), d.unsubscribe();
      });
    };
    return g(), d;
  });
  return r.hasValue = () => n, r.getValue = () => e, r;
}
let Qt;
try {
  Qt = { indexedDB: I.indexedDB || I.mozIndexedDB || I.webkitIndexedDB || I.msIndexedDB, IDBKeyRange: I.IDBKeyRange || I.webkitIDBKeyRange };
} catch {
  Qt = { indexedDB: null, IDBKeyRange: null };
}
const ce = fe;
function at(t) {
  let e = X;
  try {
    X = !0, ae.storagemutated.fire(t);
  } finally {
    X = e;
  }
}
Pe(ce, { ...tt, delete: (t) => new ce(t, { addons: [] }).delete(), exists: (t) => new ce(t, { addons: [] }).open().then((e) => (e.close(), !0)).catch("NoSuchDatabaseError", () => !1), getDatabaseNames(t) {
  try {
    return function({ indexedDB: e, IDBKeyRange: n }) {
      return an(e) ? Promise.resolve(e.databases()).then((r) => r.map((s) => s.name).filter((s) => s !== gt)) : on(e, n).toCollection().primaryKeys();
    }(ce.dependencies).then(t);
  } catch {
    return R(new S.MissingAPI());
  }
}, defineClass: () => function(t) {
  U(this, t);
}, ignoreTransaction: (t) => A.trans ? Se(A.transless, t) : t(), vip: Ht, async: function(t) {
  return function() {
    try {
      var e = Yt(t.apply(this, arguments));
      return e && typeof e.then == "function" ? e : E.resolve(e);
    } catch (n) {
      return R(n);
    }
  };
}, spawn: function(t, e, n) {
  try {
    var r = Yt(t.apply(n, e || []));
    return r && typeof r.then == "function" ? r : E.resolve(r);
  } catch (s) {
    return R(s);
  }
}, currentTransaction: { get: () => A.trans || null }, waitFor: function(t, e) {
  const n = E.resolve(typeof t == "function" ? ce.ignoreTransaction(t) : t).timeout(e || 6e4);
  return A.trans ? A.trans.waitFor(n) : n;
}, Promise: E, debug: { get: () => Y, set: (t) => {
  Cn(t, t === "dexie" ? () => !0 : Ln);
} }, derive: ke, extend: U, props: Pe, override: En, Events: Re, on: ae, liveQuery: Hr, extendObservabilitySet: Zn, getByKeyPath: ee, setByKeyPath: z, delByKeyPath: function(t, e) {
  typeof e == "string" ? z(t, e, void 0) : "length" in e && [].map.call(e, function(n) {
    z(t, n, void 0);
  });
}, shallowClone: Sn, deepClone: We, getObjectDiff: un, cmp: $, asap: Pn, minKey: Mt, addons: [], connections: Be, errnames: en, dependencies: Qt, semVer: yn, version: yn.split(".").map((t) => parseInt(t)).reduce((t, e, n) => t + e / Math.pow(10, 2 * n)) }), ce.maxKey = Ve(ce.dependencies.IDBKeyRange), typeof dispatchEvent < "u" && typeof addEventListener < "u" && (ae(Ue, (t) => {
  if (!X) {
    let e;
    mt ? (e = document.createEvent("CustomEvent"), e.initCustomEvent(se, !0, !0, t)) : e = new CustomEvent(se, { detail: t }), X = !0, dispatchEvent(e), X = !1;
  }
}), addEventListener(se, ({ detail: t }) => {
  X || at(t);
}));
let X = !1;
if (typeof BroadcastChannel < "u") {
  const t = new BroadcastChannel(se);
  typeof t.unref == "function" && t.unref(), ae(Ue, (e) => {
    X || t.postMessage(e);
  }), t.onmessage = (e) => {
    e.data && at(e.data);
  };
} else if (typeof self < "u" && typeof navigator < "u") {
  ae(Ue, (e) => {
    try {
      X || (typeof localStorage < "u" && localStorage.setItem(se, JSON.stringify({ trig: Math.random(), changedParts: e })), typeof self.clients == "object" && [...self.clients.matchAll({ includeUncontrolled: !0 })].forEach((n) => n.postMessage({ type: se, changedParts: e })));
    } catch {
    }
  }), typeof addEventListener < "u" && addEventListener("storage", (e) => {
    if (e.key === se) {
      const n = JSON.parse(e.newValue);
      n && at(n.changedParts);
    }
  });
  const t = self.document && navigator.serviceWorker;
  t && t.addEventListener("message", function({ data: e }) {
    e && e.type === se && at(e.changedParts);
  });
}
E.rejectionMapper = function(t, e) {
  if (!t || t instanceof xe || t instanceof TypeError || t instanceof SyntaxError || !t.name || !hn[t.name])
    return t;
  var n = new hn[t.name](e || t.message, t);
  return "stack" in t && Z(n, "stack", { get: function() {
    return this.inner.stack;
  } }), n;
}, Cn(Y, Ln);
class Yr extends fe {
  constructor() {
    super("NylasAuthDatabase");
    Oe(this, "grant");
    Oe(this, "pkce");
    this.version(1).stores({
      grant: "++id,token",
      pkce: "++id,codeVerifier"
    }), this.grant = this.table("grant"), this.pkce = this.table("pkce");
  }
}
class Gr {
  constructor() {
    Oe(this, "theDB");
    this.theDB = new Yr();
  }
  async setCodeVerifier(e) {
    await this.theDB.pkce.put({ id: 1, codeVerifier: e }, 1);
  }
  async getCodeVerifier() {
    const [e] = await this.theDB.pkce.toArray();
    return e ? e.codeVerifier : null;
  }
  async getTokenInfo() {
    const e = await this.theDB.grant.get(1);
    return e ? e.token : null;
  }
  async setTokenInfo(e) {
    await this.theDB.grant.put({ id: 1, token: e }, 1);
  }
  async reset() {
    await this.theDB.grant.clear();
  }
}
function es(t) {
  const e = "NylasAuth";
  if (!t)
    throw new Error("NylasAuth requires a config object");
  let n = null;
  if (t.storageType === "indexeddb" && (n = new Gr()), !n)
    throw new Error("NylasAuth requires a storage object");
  const r = [
    {
      type: "read-only",
      value: "https://www.googleapis.com/auth/gmail.readonly"
    },
    {
      type: "modify",
      value: "https://www.googleapis.com/auth/gmail.modify"
    },
    {
      type: "compose",
      value: "https://www.googleapis.com/auth/gmail.compose"
    },
    {
      type: "insert",
      value: "https://www.googleapis.com/auth/gmail.insert"
    },
    {
      type: "labels",
      value: "https://www.googleapis.com/auth/gmail.labels"
    },
    {
      type: "metadata",
      value: "https://www.googleapis.com/auth/gmail.metadata"
    },
    {
      type: "settings.basic",
      value: "https://www.googleapis.com/auth/gmail.settings.basic"
    },
    {
      type: "settings.sharing",
      value: "https://www.googleapis.com/auth/gmail.settings.sharing"
    }
  ], s = [
    {
      type: "read-only",
      value: "https://outlook.office.com/mail.read"
    },
    {
      type: "modify",
      value: "https://outlook.office.com/mail.readwrite"
    },
    {
      type: "send",
      value: "https://outlook.office.com/mail.send"
    },
    {
      type: "offline",
      value: "offline_access"
    }
  ];
  async function i(o, u, a, d = !0, c, l) {
    const h = await (n == null ? void 0 : n.getTokenInfo()), p = new URL(o, c ?? t.apiUri), g = new Headers();
    g.append("Accept", "application/json"), g.append("Content-Type", "application/json"), g.append("User-Agent", e), h && g.append("Authorization", `Bearer ${h.access_token}`);
    const f = await fetch(p.toString(), {
      method: u || "GET",
      headers: g,
      mode: "cors",
      referrer: location.origin,
      body: a ? JSON.stringify(a) : void 0,
      ...l || {}
    });
    return d ? await f.json() : f.body;
  }
  return {
    config: t,
    /**
     * This method will make a request to the Nylas API to exchange an authorization code for an access token.
     * @param scopes The scopes that were used to generate the authorization code
     * @param email The email that was used to generate the authorization code
     * @param provider The provider that was used to generate the authorization code
     * @returns A promise that resolves to a URL string that can be used to redirect the user to the Nylas API to complete the authorization flow
     */
    async getHostedAuthRedirectURL(o = t.defaultScopes, u, a) {
      const d = await ln(Math.random().toString()), c = await ln(d);
      await (n == null ? void 0 : n.setCodeVerifier(d));
      const l = new URL(`${t.apiUri}/v3/connect/auth`);
      return l.searchParams.set("client_id", t.clientId), l.searchParams.set("response_type", "code"), l.searchParams.set("scope", o.join(" ")), l.searchParams.set("redirect_uri", t.redirectURI), l.searchParams.set("access_type", "offline"), l.searchParams.set("code_challenge", c), l.searchParams.set("code_challenge_method", "S256"), l.searchParams.set("options", "rotate_refresh_token"), a && l.searchParams.set("provider", a), u && l.searchParams.set("login_hint", u), l.toString();
    },
    /**
     * This method will make a request to the Nylas API to exchange an authorization code for an access token.
     * @param path A path to the Nylas API, e.g. `/v3/grants/methreads`
     * @param method GET, POST, PUT, DELETE, etc.
     * @param body A JSON object to send as the body of the request
     * @param domain The domain to use for the request, e.g. `api.us.nylas.com`
     * @param parseJSON Whether to parse the response as JSON or return the raw response (default: true)
     * @returns
     */
    nylasAPIRequest: i,
    /**
     * This method will make a request to the Nylas API to exchange an authorization code for an access token.
     * @param code  The authorization code that was returned from the Nylas API
     * @returns A promise that resolves to a TokenExchangeResponse object
     */
    async exchangeAuthCodeForTokenInfo(o) {
      if (!o)
        return null;
      const u = await (n == null ? void 0 : n.getCodeVerifier()), a = await this.nylasAPIRequest("/v3/connect/token", "POST", {
        code: o,
        client_id: t.clientId,
        grant_type: "authorization_code",
        redirect_uri: t.redirectURI,
        code_verifier: u
      });
      return "error" in a || await (n == null ? void 0 : n.setTokenInfo(a)), a;
    },
    /**
     * This method will make a `application/octet-stream` request to the Nylas API to download a file.
     */
    async nylasDownloadFileRequest(o, u = "GET", a) {
      const d = await (n == null ? void 0 : n.getTokenInfo()), c = new URL(o, t.apiUri), l = new Headers();
      return l.append("Accept", "application/json"), l.append("Content-Type", "application/octet-stream"), l.append("User-Agent", e), d && l.append("Authorization", `Bearer ${d.access_token}`), (await fetch(c.toString(), {
        method: u,
        headers: l,
        mode: "cors",
        referrer: location.origin,
        body: a ? JSON.stringify(a) : void 0
      })).blob();
    },
    /**
     * This methid will clear the access token from the storage object
     */
    async logout() {
      const o = await (n == null ? void 0 : n.getTokenInfo());
      o && await this.nylasAPIRequest(`/v3/connect/revoke?token=${o.access_token}`, "POST"), await (n == null ? void 0 : n.reset());
    },
    /**
     * This method will make a request to the Nylas API to exchange an authorization code for an access token.
     * @returns A promise that resolves to a boolean indicating whether the user is authenticated
     */
    async isAuthenticated() {
      return !!await (n == null ? void 0 : n.getTokenInfo());
    },
    /**
     * This method will return information about the current access token.
     * @returns A promise with the token info
     */
    async getTokenInfo() {
      const o = await (n == null ? void 0 : n.getTokenInfo());
      if (!o)
        return null;
      const { access_token: u, ...a } = o;
      return a;
    },
    /**
     * This method will make a request to the Nylas API to validate if the current access token is valid.
     * @returns A promise that resolves to a boolean indicating whether the current access token is valid
     */
    async isAccessTokenValid() {
      const o = await (n == null ? void 0 : n.getTokenInfo());
      return o ? "data" in await this.nylasAPIRequest(`/v3/connect/tokeninfo?id_token=${o.id_token}`, "GET") : !1;
    },
    /**
     * This method will make a request to the Nylas API to refresh the current access token.
     * @returns A promise that resolves to a TokenExchangeResponse object
     */
    async refreshAccessToken() {
      const o = await (n == null ? void 0 : n.getTokenInfo());
      if (!o)
        return null;
      const u = await this.nylasAPIRequest("/v3/connect/token", "POST", {
        client_id: t.clientId,
        grant_type: "refresh_token",
        refresh_token: o.refresh_token
      });
      return "error" in u || await (n == null ? void 0 : n.setTokenInfo(u)), u;
    },
    /**
     * This method will return true if the current access token has the given scope
     * based on the type of scope (e.g. 'read-only', 'modify', 'compose', 'insert', 'labels', 'metadata', 'settings.basic', or 'settings.sharing')
     * @param type Either 'read-only', 'modify', 'compose', 'insert', 'labels', 'metadata', 'settings.basic', or 'settings.sharing'
     */
    async hasScopeByType(o = "email", u) {
      const a = await (n == null ? void 0 : n.getTokenInfo());
      if (!a)
        return !1;
      const d = a.scope.toLowerCase().split(" ");
      let c = [];
      const l = Array.isArray(u) ? u : [u];
      return o === "email" && (c = [...s, ...r].filter((h) => l.includes(h.type))), c.every((h) => d.includes(h.value));
    },
    /**
     * A helper method to check if the current access token has a given scope.
     * @param scope The scope(s) to check for in the current access token  (e.g. https://www.googleapis.com/auth/gmail.modify)
     * @returns boolean indicating whether the current access token has the given scope
     */
    async hasScope(o) {
      const u = await (n == null ? void 0 : n.getTokenInfo());
      if (!u)
        return !1;
      const a = u.scope.toLowerCase().split(" ");
      return (Array.isArray(o) ? o : [o]).some((c) => a.includes(c.toLowerCase()));
    }
  };
}
export {
  nr as NotificationType,
  Zr as NylasAPIError,
  es as NylasAuth,
  Xr as isNylasErrorResponse,
  Jr as isNylasSuccessResponse
};
