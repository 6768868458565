import { RegisteredComponent } from '@/common/register-component';
import { BaseNylasConnectorInterface } from '@/connector/connector-interface';
import { debug, formatEventName, uniqueID } from '@/utils/utils';
import { ObservableMap } from '@stencil/store';
import { EventOverride } from './component-types';

export type PropKey = string | number | symbol;
export type StoreKey = string;
export type StoreName<T> = keyof T;
export type StateKey = string;
export type ElementID = string;
export type PropStateRegistration = { elementId: ElementID; propKey: PropKey };
export type EventListenerRegistration = [HTMLElement, PropKey, (event: CustomEvent<any>) => Promise<void>];

export interface NylasProviderInterface<Stores extends Record<string, ObservableMap<any>>> {
  nylasConnector?: BaseNylasConnectorInterface;
  stores: Stores;
  host?: HTMLElement;
  automaticComponentRegistration: boolean;
  registeredComponents: Record<ElementID, RegisteredComponent<any, any>>;
  propStateRegistrations: Map<StoreName<this['stores']>, Map<StateKey, PropStateRegistration[]>>;
  eventListenerRegistrations: Map<ElementID, EventListenerRegistration[]>;
  componentWillLoad(nylasConnector: BaseNylasConnectorInterface): Promise<void>;
  componentDidLoad(): Promise<void>;
  componentDisconnected(): Promise<void>;
  getStore<K extends keyof ThisType<this['stores']>>(name: K): ThisType<this['stores']>[K];
  registerComponent(component: RegisteredComponent<any, any>): void;
  unregisterComponent(component: RegisteredComponent<any, any>): void;
}

export abstract class NylasAbstractProvider<Stores extends Record<string, ObservableMap<any>>> implements NylasProviderInterface<Stores> {
  /**
   * The NylasConnector instance.
   * This is used to provide access to the NylasConnector instance to all components.
   */
  public nylasConnector?: BaseNylasConnectorInterface;

  /**
   * A list of stores that are used by the provider.
   * This is used to provide access to the stores to all components.
   */
  public stores: Stores;

  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  public host!: HTMLElement;

  /**
   * Automatically register components that have the `@RegisterComponent` decorator.
   * If this is set to false, you will need to manually register components using the
   * `registerComponent` method.
   * @default true
   */
  public automaticComponentRegistration: boolean = true;

  /**
   * A list of registered components that are listening for store changes.
   * Each component is registered with a unique id so that we can easily track the component
   * for various other operations (see `propStateRegistrations` and `eventListenerRegistrations`).
   */
  public registeredComponents: Record<ElementID, RegisteredComponent<any, any>> = {};

  /**
   * A list of registered components that are listening for store changes.
   * To make lookups more efficient, we use a Map of our prop registrations that we need to map to differnet
   * state keys. When a component is unregistered, we can use this map to remove the prop registration.
   */
  public propStateRegistrations: Map<StoreName<this['stores']>, Map<StateKey, PropStateRegistration[]>> = new Map();

  /**
   * A list of registered event listeners.
   * To make lookups more efficient, we use a Map of our event listeners that we need to map to differnet
   * nylas connector methods. When a component is unregistered, we can use this map to remove the event listener.
   */
  public eventListenerRegistrations: Map<ElementID, EventListenerRegistration[]> = new Map();

  /**
   * A list of event overrides that are used by the provider.
   * This is used to provide an easy way to override the default function of the event emitter.
   * An example of this is the `timeslotConfirmed` event. By default, this event will set the scheduler store state for `showBookingForm` to `true` which will
   * show the booking form. However, if you want to override this behavior, you can pass in the prop `eventOverride` like:
   * ```html
   * <nylas-scheduler eventOverride={{"timeslotConfirmed": (event, nylasConnector) => { console.log("Timeslot confirmed event fired!"); } }} />
   * ```
   */
  public eventOverrides: EventOverride<Exclude<typeof this.nylasConnector, undefined>>;

  /**
   * The constructor for the AbstractNylasProvider.
   * @param host  The host element (usually the HTMLElement of the provider)
   * @param stores A list of stores that are used by the provider
   * @param automaticComponentRegistration A boolean that determines if components should be automatically registered
   * @param eventOverrides A list of event overrides that are used by the provider
   */
  constructor(host: HTMLElement, stores: Stores, automaticComponentRegistration: boolean = true, eventOverrides: EventOverride<any>) {
    this.host = host;
    this.stores = stores;
    this.automaticComponentRegistration = automaticComponentRegistration;
    this.eventOverrides = eventOverrides;

    debug(`[${this.hostTagName}] Initializing (abstract) provider.`, { eventOverrides });

    this.registerComponent = this.registerComponent.bind(this);
    this.getStore = this.getStore.bind(this);

    // Init propStateRegistrations with store names
    Object.keys(this.stores).forEach(storeName => {
      this.propStateRegistrations.set(storeName, new Map());
    });
  }

  /**
   * This method is called before the component is loaded.
   * We're passing the NylasConnector instance to the provider so that we can
   * provide access to the NylasConnector instance to all components.
   *
   * However, because the NylasConnector is often constructed after the component's
   * constructor is called, set the NylasConnector instance here.
   * @param nylasConnector The NylasConnector instance
   */
  async componentWillLoad(nylasConnector: BaseNylasConnectorInterface) {
    debug(`[${this.hostTagName}] Will load`);

    // For each of our stores, configure the listeners for changes in the
    // store state.
    this.setupStoreListeners();

    // Set the NylasConnector instance
    this.nylasConnector = nylasConnector;

    /**
     * TODO: Pooja and Levon, please revisit this registration method. See if we can optimize or get rid of it
     * completely.
     *
     * This is a very static way of registering components, but it happens fairly
     * early in the component lifecycle, so it can be beneficial to do it here.
     *
     * The big downside to this is that it will not pick up any components that
     * are dynamically added to the DOM after this method is called.
     *
     * So we should evaluate if this is the best way to register components.
     */
    const childComponents = Array.from(this.host.querySelectorAll('*')).filter(child => child.tagName.toLowerCase().startsWith('nylas-'));
    debug(`[${this.hostTagName}] Found ${childComponents.length} child component(s).`, { childComponents });

    childComponents.forEach(child => {
      const component = child as any; // It unfortunately makes life a whole lot easier to just cast this to any
      if (!component) {
        debug(`[~${this.hostTagName}] Component ${component.tagName} does not have a name. Skipping.`);
        return;
      }

      // Skip components that don't have the `registerNylasComponent` prop
      if (!component.registerNylasComponent) {
        debug(`[~${this.hostTagName}] Component ${component.tagName} is not a component that can be registered. Skipping.`, { component });
        return;
      }

      const registeredComponent: RegisteredComponent<any, any> = {
        element: component as HTMLElement,
        name: component.tagName.toLowerCase(),
        getStoresToProp: component.getStoresToProp,
        storeToProps: component.storeToProps,
        stateToProps: component.stateToProps,
        authToProp: component.authToProp,
        eventToProps: component.eventToProps,
        connectorToProp: component.connectorToProp,
        localPropsToProp: component.localPropsToProp,
      };

      this.registerComponent(registeredComponent);
    });
  }

  /**
   * This method is called after the component is loaded.
   * We're using this method to add event listeners to the host element.
   */
  async componentDidLoad() {
    debug(`[${this.hostTagName}] Did load`);
  }

  /**
   * This method is called when the component is disconnected from the DOM.
   * We're using this method to dispose of the stores.
   */
  async componentDisconnected() {
    // Loop through each store and and dispose
    Object.values(this.stores).forEach(store => {
      store.dispose();
    });
  }

  /**
   * This is a custom event handler that is used to register a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to register
   * @returns Promise<void>
   */
  async registerComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    event.stopPropagation();

    if (!this.automaticComponentRegistration) {
      debug(`[${this.hostTagName}] Automatic component registration disabled. Skipping registration of ${event.detail.name}.`);
      return;
    }

    this.registerComponent(event.detail);
  }

  /**
   * This is a custom event handler that is used to unregister a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to unregister
   * @returns Promise<void>
   */
  async unregisterComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    event.stopPropagation();

    if (!this.automaticComponentRegistration) {
      debug(`[${this.hostTagName}] Automatic component registration disabled. Skipping unregistration of ${event.detail.name}.`);
      return;
    }

    this.unregisterComponent(event.detail);
  }

  /**
   * This method is used to register a component with the provider
   * @param component component to register
   * @returns void
   */
  public registerComponent(component: RegisteredComponent<any, any>): void {
    debug(`[${this.hostTagName}] Registering component ${component.name}.`);

    // Get component element id
    const elementId = component.element.dataset.nylasId;

    // Make sure the component is not already registered
    if (elementId && this.registeredComponents[elementId]) {
      debug(`[${this.hostTagName}] Component ${component.name} already registered. Skipping.`);
      return;
    }
    const { stateToProps, getStoresToProp, eventToProps, storeToProps, connectorToProp, localPropsToProp, element } = component;

    // Set a unique data id on the element so we can track it. We set this on the element
    // so that we can easily find the element later.
    element.dataset.nylasId = uniqueID();

    // Register the component
    this.registeredComponents[element.dataset.nylasId] = component;

    const eventOverrides = this.eventOverrides;

    /**
     * We allow components to map an event to a prop. These events can automatically
     * call a method on the nylasConnector instance.
     */
    eventToProps?.forEach((customEventHandler, propKey) => {
      const nylasConnector = this.nylasConnector;
      const handler = async (event: CustomEvent<any>) => {
        debug(`[${this.hostTagName}] Handling "${component.name}" prop "${String(propKey)}" event.`, { event });
        if (propKey in eventOverrides) {
          debug(`[${this.hostTagName}] Found event override for "${String(propKey)}" event. Calling override.`);
          await eventOverrides[propKey](event, nylasConnector);
        }

        if (!event.defaultPrevented) {
          await customEventHandler(event, nylasConnector);
        }

        return;
      };

      const elementId = component.element.dataset.nylasId;

      if (elementId && !this.eventListenerRegistrations.has(elementId)) {
        this.eventListenerRegistrations.set(elementId, []);
      }

      if (elementId) {
        this.eventListenerRegistrations.get(elementId)?.push([element, propKey, handler]);
      }

      debug(`[${this.hostTagName}] Setting "${component.name}" event "${String(propKey)}" to automcially call NylasConnector method.`);

      // We should immediately call the event handler to make sure the prop is set
      const eventName = formatEventName(propKey.toString());
      debug(`[${this.hostTagName}] Adding event listener for "${eventName}" on "${element.tagName}"`);
      element.addEventListener(eventName, handler as unknown as EventListener);
    });

    /**
     * This is a rather simple way for us to set the initial props for a component.
     * For each prop, we check if the prop is mapped to a store. If it is, we set
     * the prop to the store value.
     */
    stateToProps?.forEach((propKey, stateKey) => {
      const [storeName, stateName] = stateKey.split('.');
      const store = this.getStore(storeName as keyof typeof this.stores);
      const stateValue = store.state[stateName as keyof typeof store.state];

      // Make sure this component was registered and has a nylasId
      if (!element.dataset.nylasId) {
        debug(`[${this.hostTagName}] Component "${component.name}" not registered. Skipping.`, { component });
        return;
      }

      // We need to keep track of the store and prop key so that we can update the
      // prop when the store changes
      if (!this.propStateRegistrations.has(storeName as keyof typeof this.stores)) {
        this.propStateRegistrations.set(storeName as keyof typeof this.stores, new Map());
      }

      if (!this.propStateRegistrations.get(storeName as keyof typeof this.stores)?.has(stateName)) {
        this.propStateRegistrations.get(storeName as keyof typeof this.stores)?.set(stateName, [
          {
            elementId: element.dataset.nylasId,
            propKey,
          },
        ]);
      } else {
        this.propStateRegistrations
          .get(storeName as keyof typeof this.stores)
          ?.get(stateName)
          ?.push({
            elementId: element.dataset.nylasId,
            propKey,
          });
      }

      // Set the prop value on the component
      (element as { [key: string]: any })[propKey.toString()] = stateValue;
      debug(`[${this.hostTagName}] Setting "${component.name}" prop "${propKey.toString()}" to "${stateKey}" value.`, { stateValue });
    });

    /**
     * We allow components to also map props from the provider to the component.
     */
    localPropsToProp?.forEach((propKey, value) => {
      const mappedPropValue = this.host[value]; // TODO: Is this safe? We should find a way to only limit it to public properties and no methods.
      (element as { [key: string]: any })[propKey.toString()] = mappedPropValue;
      debug(`[${this.hostTagName}] Setting "${component.name}" prop "${propKey.toString()}" to the value of "${value}" value.`, { value, mappedPropValue });
    });

    /**
     * We allow components to access the getStore method directly.
     */
    if (getStoresToProp) {
      (element as { [key: string]: any })[getStoresToProp?.toString()] = this.getStore;
      debug(`[${this.hostTagName}] Setting "${component.name}" prop "${getStoresToProp.toString()}" to "getStore" method.`);
    }

    /**
     * We allow components to map a store to a prop for direct access.
     */
    storeToProps?.forEach((propKey, storeKey) => {
      const store = this.getStore(storeKey);
      (element as { [key: string]: any })[propKey.toString()] = store;
      debug(`[${this.hostTagName}] Setting "${component.name}" prop "${propKey.toString()}" to "${storeKey}" store.`, { store });
    });

    /**
     * We allow components to map the NylasConnector instance to a prop for direct access.
     */
    if (connectorToProp) {
      (element as { [key: string]: any })[connectorToProp?.toString()] = this.nylasConnector;
      debug(`[${this.hostTagName}] Setting "${component.name}" prop "${connectorToProp.toString()}" to "nylasConnector" value.`, { connectorToProp });
    }

    debug(`[${this.hostTagName}] Component ${component.name} registered.`);
  }

  /**
   * This is a method that is used to unregister a component with the provider.
   * @param component HTMLElement to unregister
   * @returns void
   */
  public unregisterComponent(component: RegisteredComponent<any, any>): void {
    debug(`[${this.hostTagName}] Unregistering component ${component.name}.`);

    // Get component element id
    const elementId = component.element.dataset.nylasId;
    if (!elementId) {
      debug(`[${this.hostTagName}] Component ${component.name} not registered. Skipping.`);
      return;
    }

    // Make sure the component is not already registered
    if (this.registeredComponents[elementId]) {
      debug(`[${this.hostTagName}] Component ${component.name} not registered. Skipping.`);
      return;
    }

    // Remove the component prop registration from propStateRegistrations
    this.propStateRegistrations.forEach((stateToProps, storeName) => {
      stateToProps.forEach((_, stateKey) => {
        const props = this.propStateRegistrations.get(storeName)?.get(stateKey);
        const filteredProps = props?.filter(prop => prop.elementId !== elementId);
        if (filteredProps) {
          this.propStateRegistrations.get(storeName)?.set(stateKey, filteredProps);
        }
      });
    });

    // Before we unregister the component, we need to remove any event listeners
    const eventListenerRegistrations = this.eventListenerRegistrations.get(elementId);
    eventListenerRegistrations?.forEach(([element, propKey, handler]) => {
      const eventName = formatEventName(propKey.toString());
      debug(`[${this.hostTagName}] Removing event listener for "${eventName}" on "${element.tagName}"`);
      element.removeEventListener(eventName, handler as unknown as EventListener);
    });

    // Unregister the component
    delete this.registeredComponents[elementId];
  }

  /**
   * This method is used to dynamically retrieve the appropriate store
   * @param name store name
   */
  public getStore<K extends keyof Stores>(name: K): Stores[K] {
    const store = this.stores[name];
    if (!store) {
      throw new Error(`[${this.hostTagName}] Store "${name.toString()}" not found.`);
    }
    return store;
  }

  /**
   * This method is called when the component is connected to the DOM.
   * We're using this method to listen for changes in the store and update
   * the registered components with the new values.
   */
  private async setupStoreListeners() {
    const self = this; // eslint-disable-line @typescript-eslint/no-this-alias

    /**
     * This is a rather simple way for us to listen for changes in the store
     * and make sure our registered components are updated with the new values.
     *
     * For each store change, we loop through each registered component and
     * check if the store change is mapped to a prop on the component. If it is,
     * we set the prop to the new value.
     *
     * We call this super early in the provider lifecycle so that we can make sure
     * that all components have the correct props before they are rendered.
     */
    Object.entries(this.stores).forEach(([storeName, store]) => {
      debug(`[${self.hostTagName}] Listening for changes in "${storeName}" store.`);
      store.use({
        set(stateKey, newValue, oldValue) {
          debug(`[${self.hostTagName}] Store "${storeName}" state "${stateKey.toString()}" changed`, { newValue, oldValue });
          if (newValue === oldValue) {
            debug(`[${self.hostTagName}] Store "${storeName}" state "${stateKey.toString()}" unchanged. Skipping.`);
            return;
          }

          const registeredComponents = self.registeredComponents;
          if (!registeredComponents) {
            debug(`[${self.hostTagName}] No registered components found. Skipping.`);
            return;
          }

          // Get our prop registrations for this store
          const props = self.propStateRegistrations.get(storeName as keyof typeof self.stores as string)?.get(stateKey.toString()) || [];
          debug(`[${self.hostTagName}] Found ${props.length} prop(s) registered for "${stateKey.toString()}" store.`, {
            props,
            propStateRegistrations: self.propStateRegistrations,
          });

          // Loop through each registered component and update the prop
          props.forEach(({ elementId, propKey }) => {
            const component = registeredComponents[elementId];
            if (!component) {
              debug(`[${self.hostTagName}] Component "${elementId}" not found. Skipping.`, { component });
              return;
            }

            // Get the appropriate store
            const loadedStore = self.getStore(storeName as keyof typeof self.stores as string);
            const stateValue = loadedStore.state[stateKey as unknown as keyof typeof loadedStore.state];
            (component.element as { [key: string]: any })[propKey.toString()] = stateValue;
            debug(`[${self.hostTagName}] Setting "${component.name}" prop "${propKey.toString()}" to "${stateKey.toString()}" value.`, { stateValue });
          });
        },
      });
    });
  }

  /**
   * Simple getter for the host element tag name.
   */
  private get hostTagName(): string {
    return this.host?.tagName?.toLowerCase() ?? 'nylas-provider';
  }
}

export class NylasBaseProvider<T extends Record<string, ObservableMap<any>>> extends NylasAbstractProvider<T> {}
